import React from 'react';

const Loader = () => {
    return(
        <div className="d-flex flex-column justify-content-center align-items-center" style={{width: '100%' , height: '200px'}}>
           <div class="spinner-border" role="status">
                
            </div>
            <span class="sr-only mt-2">Loading...</span>
        </div>
    )
}


export default Loader;