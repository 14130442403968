import { Divider } from '@mui/material';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import style from '../components.module.css';


const Summary = ({
    title,
    referenceNumber,
    familyPolicy,
    policyHolder,
    policyHolderSurname,
    coverAmount,
    premiumAmount,
    isFail = false,
    isPass = false
}) => {
    return(
        <div className={style.summary_container}>
             {isFail ? <Row className="justify-content-center">
                <Col lg={12}>
                    <h3 className="primary-color f-700 text-center">Oops!</h3>
                    <p className="primary-color f-700 text-center">We seem to struggle with the payment transaction.</p>
                </Col>
            </Row> : isPass ? <Row className="justify-content-center">
                <Col lg={12}>
                    <h3 className="primary-color f-700 text-center">All Done!</h3>
                    <p className="primary-color f-700 text-center">Payment successful</p>
                </Col>
            </Row> : <></>}

            <Row className="justify-content-center">
                <Col lg={12}>
                    <h3 className="primary-color f-700 text-center">{title ?? ''}</h3>
                    
                </Col>
            </Row>

            <Row className="justify-content-between mt-4">
                <Col lg={6}>
                    <p className="primary-color f-700 text-left">Reference number :</p>
                </Col>
                <Col lg={6}>
                    <p className="primary-color f-700 text-right w-100">{referenceNumber}</p>
                </Col>
            </Row>


            <Row className="justify-content-between mt-0">
                <Col lg={6}>
                    <p className="primary-color f-700 text-left">Policy holder :</p>
                </Col>
                <Col lg={6}>
                    <p className="primary-color f-700 text-right w-100">{policyHolder}  {policyHolderSurname}</p>
                </Col>
            </Row>

            <Row className="justify-content-between mt-0">
                <Col lg={6}>
                    <p className="primary-color f-700 text-left">Family policy :</p>
                </Col>
                <Col lg={6}>
                    <p className="primary-color f-700 text-right w-100">{familyPolicy} </p>

                </Col>
            </Row>

            {!isFail && <Row className="justify-content-between mt-0">
                <Col lg={6}>
                    <p className="primary-color f-700 text-left">Cover Amount :</p>
                </Col>
                <Col lg={6}>
                    <p className="primary-color f-700 text-right w-100">GHS {(Number.isInteger(parseFloat(coverAmount)) ? parseInt(coverAmount)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : parseFloat(coverAmount)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))} </p>
                </Col>
            </Row>}
            <Divider />

            <Row className="justify-content-between mt-4">
                <Col lg={6}>
                    <p className="primary-color f-700 text-left">Monthly Premium :</p>
                </Col>
                <Col lg={6}>
                    <p className="primary-color f-700 text-right w-100">GHS {premiumAmount}</p>
                </Col>
            </Row>

            <Divider />

        
            
            <Divider />

            <Row className="justify-content-start mt-3">
                <Col lg={12}>
                    <p className="primary-color f-700 text-left m-0 w-100">Support contact details:</p>
                    <ul>
                        <li className="primary-color f-500 text-left m-0 w-100">info.gh@letshego.com</li>
                        <li className="primary-color f-500 text-left m-0 w-100">0577699923 / 0302208333</li>
                    </ul>
                </Col>
            </Row>
            

        </div>
    )
}


export default Summary;