// default imports for react project
import React, { useEffect, useState } from "react";

// import default styles from react-bootstrap package
import { Col, Row } from "react-bootstrap";

// Import images from assets folder

//   import custom component
import { saham, fi, hi, li, ci, sanlam } from "../../assets/images";
import {useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  executeCalculator,
  getProductRisks,
  getProductRisksAttributes,
  getProductTypeDetails,
  updatedAttributes,
  clearState,
} from "../../store/actions/products";
import {
  getAgentOnboarding,
  login,
} from "../../store/actions/auth";
import { RISKS } from "../../utils/constants";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { parseQuery, setParam } from "../../utils/utils";
import {trackUserEvents } from "../../utils/utils";

// React functional component starts here..
function Card2({ width }) {
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const reduxState = useSelector((state) => state);
  const state = useSelector((state) => state?.products.riskAttributes);
  const [coverAmount, setCoverAmount] = useState("");
  const currentToken = useSelector((state) => state.auth.currentUserToken);

  useEffect(() => {
    dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  
  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    if (location.pathname !== "/payment-success") {
      setParam(history, currentToken);
    }
  }, [currentToken]);

  useEffect(() => {
    if(!reduxState?.products?.riskAttributes?.length) return;
    var arr = [...reduxState?.products?.riskAttributes?.find((x) => x.code === 'QUQ')?.attributes]
    const index = reduxState?.products?.riskAttributes?.find((x) => x.code === 'QUQ')?.attributes?.findIndex((y) => y.name === 'Cover Amount');
    arr[index] = {...arr[index], value: coverAmount}
    dispatch(updatedAttributes(arr, 'QUQ'))
  },[coverAmount])

  const agentCode = useSelector((state) => state.auth.agentCode);

  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

  const [isLoading, setIsLoading] = useState(false);
  const countDown = useSelector((state) => state?.products?.countDown?.count) ?? 0;
 

  const [errors, setErrors] = useState({
    coverAmount: []
  })
  const [isCounter, setIsCounter] = useState(false);
  let timer = null;


  const handleCalculator = async () => {
    if(parseInt(coverAmount) === 0 || coverAmount === null || coverAmount === undefined || coverAmount === ""){
      return setErrors({
        coverAmount: ["Cover amount can't be blank"]
      })
    }
    
    if(parseInt(coverAmount) < 5000 || parseInt(coverAmount) > 100000){
      return setErrors({
        coverAmount: ['Cover amount should be between from 5,000 to 100,000']
      })
    }

    try{
      setIsLoading(true);
      setIsCounter(true);
      if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentOnboarding(agentCode));
      }

      const riskRes = await dispatch(getProductRisks());
      const productTypes = await dispatch(getProductTypeDetails());
      let risksAttrs = [];
      const asyncForEach = async (arr, callback) => {
        for (let i = 0; i < arr.length; i++) {
          await callback(arr[i]);
        }
      };
      const act = async () => {
        await asyncForEach(riskRes.risks, async (risk) => {
          risksAttrs = [
            ...risksAttrs,
            await dispatch(
              getProductRisksAttributes(risk.instanceId, coverAmount)
            ),
          ];
        });
      };
      await act();
      const res = await dispatch(
        executeCalculator({
          payload: {
            factors: [],
            productTypeReference: productTypes.types[0].instanceId,
            risks: [
              {
                attributes: [
                  ...risksAttrs
                    ?.find((x) => x.code === RISKS.QUQ)
                    ?.attributes.map((y) => ({
                      dataType: y.dataType,
                      name: y.name,
                      instanceId: y.instanceId,
                      value: y.name === "Cover Amount" ? coverAmount : null,
                    })),
                ],
                riskReference: risksAttrs.find((x) => x.code === RISKS.QUQ)
                  ?.instanceId,
                code: risksAttrs.find((x) => x.code === RISKS.QUQ)?.code,
              },
            ],
          },
          type: "pre",
        })
      );
      history("/forms");
      //trackUserEvents
      trackUserEvents("gh_insurnace_get_a_quote_cta", {});
      setIsLoading(false);
      setIsCounter(false);
      return;
    }
    catch(err){
      console.log(err);
    }
  }

  
  return (
    <div>
    <Col lg xs={12}>
      <Row className="m-0  card__homepage ">
        <div
          style={{
            backgroundColor: "#E5E5F5",
            padding: width < 430 ? "0px" : "",
          }}
        >
          <Row className="m-4">
            <Col lg={10}>
              <h3 style={{ fontSize: "26pt", color: "#2F2E80" }}>
              Select the right insurance to give you the right cover.{" "}
              </h3>
              <h6
                style={{
                  fontSize: "13pt",
                  color: "#2F2E80",
                  fontWeight: "normal",
                }}
              >
                If something was to happen to you, you can make sure your family
                is protected
              </h6>
            </Col>
            <Col lg={2}>
              <Row>
              <h6
                  style={{
                    color: "#56616C",
                    fontSize: "10pt",
                    fontWeight: "bold",
                    textAlign: "center", 
                    marginTop:"5px"
                  }}
                >Policy underwritten by</h6>
                </Row>
                <Row>
                <div
                  className={`d-flex ${
                    width > 770
                      ? "justify-content-start"
                      : "justify-content-center"
                  }`}
                >
                  <img
                    src={sanlam}
                    style={{
                      marginLeft: "36.2px",
                      width: "95px",
                      marginTop:"1px"
                    }}
                    alt="sanlam"
                  />
                </div>
                </Row>
            </Col>
          </Row>
          <Row>
            <hr
              style={{
                color: "white",
                height: 2,
                width: "97%",
                margin: "auto",
              }}
            />
          </Row>

          <Row className="secondary-container p-3 ">
            <Row className="secondary-container p-3 ">
              <Col lg={7}>
                <Row>
                  <Col lg={3} style={{textAlign:"center"}}>
                    <img src={fi} alt="fi" style={{
                           width:"60%",
                      }}/>
                  </Col>
                  <Col lg={9}>
                  <h4
                      style={{
                        fontSize: "20px",
                        color: "#2F2E80",
                        fontWeight: "bold",
                      }}
                    >
                      Family Care Assurance
                    </h4>
                    <span style={{color: "#2F2E80", fontWeight: 'bold',marginTop:"12px"}}>Covers options:</span>
                    <div style={{display:'flex'}}>
                    <ul>
                    <li style={{ color: "#2F2E80"}}><span style={{color:"#2F2E80"}}>Our <span style={{color:"#2F2E80",fontWeight:"bold"}}>  Family Package</span> includes Policyholder, one spouse and up to a
                      maximum of four children.</span></li>
                      <div style={{ height: "20px" }} />
                      <li style={{ color: "#2F2E80"}}><span style={{color:"#2F2E80"}}>With our<span style={{color:"#2F2E80",fontWeight:"bold"}}> Extened Family Package</span> you can add up to six
                      additional family members.</span></li>
                      <div style={{ height: "20px" }} />
                      <li style={{ color: "#2F2E80"}}><span style={{color:"#2F2E80"}}>With benefits options from:&nbsp;GHS 5,000 up to GHS 100,000.</span></li>
                      <div style={{ height: "20px" }} />
                      <li style={{ color: "#2F2E80"}}><span style={{color:"#2F2E80"}}>All valid claims are paid in less than 48 hours after all
                      the required claim documents have been submitted.</span></li>
                      <div style={{ height: "20px" }} />
                    </ul>
                    </div> 
                  </Col>
                </Row>
              </Col>

              <Col lg={5} className="d-flex justify-content-center align-items-center">
                <div className="purple_card pl-5 pr-5 pt-4 pb-4 d-flex align-items-center flex-column"  style={{height:"100%"}}>
                <Row style={{ paddingLeft: "0px", paddingRight: "0px" ,marginTop:"3px"}}>
                <h4
                  style={{
                    fontSize: "20px",
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                   Family Care Assurance
                  </h4>     
                  </Row>
                  <Row style={{marginBottom:"10px"}}>
                  <h4
                    style={{
                      fontSize: "10pt",
                      textAlign: "center",
                      color: "white",
                      fontWeight:"bold",
                      marginBottom:"5px"
                    }}
                  >
                   2 cover options to choose from<br/>

                  </h4>
                </Row>
                  <Row> 
                  <div className="whiteoverlay_card justify-content-center p-1">
                    <h2 className="mt-2">Add how much cover you need?</h2>
                    <form className="d-flex justify-content-center flex-column">
                      <input
                        className="w-100"
                        type="text"
                        id="inputBox"
                        onChange={(e) => {
                          setCoverAmount(isNaN(Number(e.target.value.charAt(e.target.value.length-1))) ? e.target.value.replace(e.target.value.charAt(e.target.value.length-1),"") : e.target.value)
                        }
                      }
                        placeholder="Eg: GHS 5,000 up to max 100,000"
                        style={{ borderRadius: "6px", border: "none",fontSize:"14px",}}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        value={coverAmount}
                      />
                      <span className="errors text-white">{errors?.coverAmount?.[0]}</span>
                    </form>
                    <div className="d-flex justify-content-center">
                      <button
                        onClick={handleCalculator}
                        className="purpleButton pt-2 mt-3 pb-2 pl-3 pr-3"
                      >
                        {isLoading ? 'Quoting..' : 'Get a quote'}
                      </button>
                    </div>
                  </div>
                  </Row>
                  <div style={{ height: "20px" }} />
                  <div style={{ height: "20px" }} />
                  <div style={{ height: "20px" }} />
                  <div style={{ height: "20px" }} />
                  <div style={{ height: "20px" }} />
                  <hr
                  style={{
                    color: "white",
                    height: 1,
                    width: "97%",
                    margin: "auto",
                  }}
                />
            <Row
                className="justify-content-center"
                style={{
                  fontSize: "9pt",
                  color: "white",
                  textAlign: "center",
                  marginBottom: "5px",
                  padding:"14px",
                }}
              >
                By opting to buy insurance, Letshego will share the required
                information with the insurer to facilitate your policy purchase.
              </Row>
              <Row>
              <h4
                  style={{
                    color: "white",
                    fontSize: "10pt",
                    textAlign: "center", 
                    marginTop:"5px"
                  }}
                >Policy underwritten by</h4>
                </Row>
                <Row>
                <div
                  className={`d-flex ${
                    width > 770
                      ? "justify-content-start"
                      : "justify-content-center"
                  }`}
                >
                  <img
                    src={sanlam}
                    style={{
                      marginBottom:"5px",
                      width: "95px",
                    }} 
                    alt="sanlam"
                  />
                </div>
            </Row>  
                </div>
              </Col>
            </Row>
          </Row>
        </div>
      </Row>
    </Col>
    {isLoading && (
        <div
          style={{
            height: "99vh",
            width: "100%",
            zIndex: 100,
            backgroundColor: "#00000050",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "0px",
          }}
        >
          <div style={{height: 100, width: 100}}>
            <CircularProgressbar styles={buildStyles({textColor: '#fbcf2c', pathColor:'#fbcf2c'})} value={countDown} text={`${countDown}%`} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Card2;

