import classNames from "classnames";
import React, { useState } from "react";
import {useSelector } from "react-redux";
import NavigationButtons from "../../../components/NavigationButtons";
import useWindowDimensions from "../../../utils/useWindowDimension";
import style from "../Form.module.css";
import ReactSelect from "react-select";
import {trackUserEvents } from "../../../utils/utils";

const Step3 = ({ handleNext, handleBack }) => {
  const [inputs, setInputs] = useState({
    coverOptions: 0,
    options: 0,
    children: 0,
    additionalChildren: 0,
    parent: 0,
    parentAbove65: 0,
    parentAbove80: 0,
  });

  const { width } = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(false);

  const quqRiskAttributes = useSelector((state) => state.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes);

  const customAttributes = useSelector((state) => state?.products?.customAttributes);
  const selectedOption = useSelector(
    (state) => state?.products?.selectedOption
  );
  const coverAmount = useSelector(
    (state) =>
      state?.products?.riskAttributes
        ?.find((y) => y.code === "QUQ")
        ?.attributes?.find((x) => x.name === "Cover Amount")?.value
  );
  const calculatorResponse = useSelector(
    (state) => state?.products?.executeCalculator2?.productOptions?.[0]
  );
  const getAttributes = (value) => {
    return quqRiskAttributes.find((x) => x.name === value);
  };

   const handleSubmit = () => {
     //trackUserEvents
     trackUserEvents("gh_insurance_final_quote_next_cta", {});
     handleNext();
   };

   const handleBackTrack = () => {
     //trackUserEvents
     trackUserEvents("gh_insurance_final_quote_back_cta", {});
     handleBack();
   };

  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Family Care Assurance</h3>
            <h2 className="primary-color f-800">Complete your details</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Family Care Assurance</p>
          <h2 className="primary-color f-800">Complete your details</h2>
        </div>
      )}

      <div className="d-flex justify-content-center align-items-center flex-column mt-1 mb-4">
        <div style={{ maxWidth: "460px", width: "100%", marginTop: "2vh" ,textAlign:"center"}}>
          <h5 className="text-left" style={{ color: "#3C4096" }}>
            Your final quote
          </h5>
        </div>

        
          <div className={style.product_card}>
            <h5 className="text-white text-center f-700">{calculatorResponse?.risks?.[0]?.rating?.message?.split(':')[1] ?? ""}</h5>
            {[selectedOption]?.map((item) => (
            <div className={classNames(style.product_card_sub, "mt-3")}> 
              <h6 style={{ color: "#FBD105" }}>Premium</h6>
              <div className="d-flex justify-content-between">
                <h6 style={{ color: "#3C4096" }}>Monthly cost</h6>
                <h6 style={{ color: "#3C4096" }}>
                  GHS{" "}
                  {calculatorResponse?.risks?.[0]?.rating?.total
                    .toFixed(2)
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0.0}
                </h6>
              </div>

              <h6 className="mt-4" style={{ color: "#FBD105" }}>
                Level of Cover
              </h6>
              <div className="d-flex justify-content-between">
                <h6 style={{ color: "#3C4096" }}>Main member</h6>
                <h6 style={{ color: "#3C4096" }}>
                  GHS{" "}
                  {(Number.isInteger(parseFloat(coverAmount)) ? parseInt(coverAmount)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : parseFloat(coverAmount)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))}
                </h6>
              </div>
              {customAttributes?.spouseCover !== "No" && (
              <>
              <div className="d-flex justify-content-between">
                <h6 style={{ color: "#3C4096" }}>Spouse</h6>
                <h6 style={{ color: "#3C4096" }}>
                  GHS{" "}
                  {(Number.isInteger(parseFloat(coverAmount)) ? parseInt(coverAmount)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : parseFloat(coverAmount)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))}
                </h6>
              </div>
              </>)}

              {customAttributes?.childCover !== "No" && (
              <>
              <div className="d-flex justify-content-between">
                <h6 style={{ color: "#3C4096" }}>Children</h6>
                <h6 style={{ color: "#3C4096" }}>
                  GHS{" "}
                  {(Number.isInteger(parseFloat(coverAmount)/2) ? (parseInt(coverAmount)/2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (parseFloat(coverAmount)/2)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))}
                </h6>
              </div>
              </>)}

              {getAttributes("Extended Family Cover")?.value !== "No" && (
              <>  
              <div className="d-flex justify-content-between">
                <h6 style={{ color: "#3C4096" }}>Extended family</h6>
                <h6 style={{ color: "#3C4096" }}>
                  GHS{" "}
                  {(Number.isInteger(parseFloat(coverAmount)/2) ? (parseInt(coverAmount)/2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (parseFloat(coverAmount)/2)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))}
                </h6>
              </div>
              </>)}
            </div>            
          ))}
          </div>

      </div>

      <div style={{ height: "20px" }} />

      <div className={style.step_1_price_summary}>
        <div className={style.step_1_price_summary_sub}>
          <h5 className="text-white f-700">Total Monthly Premium</h5>
          <h5 className="text-white f-700">
            GHS{" "}
            {(calculatorResponse?.risks?.[0]?.rating?.total)?.toFixed(2)
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}
          </h5>
        </div>
      </div>

      <NavigationButtons
        back={handleBackTrack}
        payment={false}
        next={() => {
          handleSubmit();
        }}
      />
    </div>
  );
};

export default Step3;
