import classNames from "classnames";
import React, { useEffect, useState } from "react";
import NavigationButtons from "../../../components/NavigationButtons";
import useWindowDimensions from "../../../utils/useWindowDimension";
import { useDispatch, useSelector } from "react-redux";
import style from "../Form.module.css";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import {
  updateCustomAttributes,
  updatedAttributes,
} from "../../../store/actions/products";
import { validate } from "validate.js";
import { useNavigate } from "react-router";
import {toast} from 'react-toastify';
import { trackUserEvents } from "../../../utils/utils";

const Step1 = ({ handleNext, handleBack }) => {
  const [inputs, setInputs] = useState({
    coverOptions: null,
    options: null,
    children: null,
    additionalChildren: null,
    parent: null,
    parentAbove65: 0,
    parentAbove80: 0,
  });

  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const state = useSelector((state) => state.products.riskAttributes);
  const history = useNavigate();

  const protectionRequirement = state
    .find((x) => x.code === RISKS.QUQ)
    ?.attributes?.find((y) => y.name === ATTRIBUTES.PROTECTION_REQUIREMENT);

  const protectionIndividual = useSelector(
    (state) => state.products?.protectionIndividual
  );

  const protectionFamily = useSelector(
    (state) => state.products?.protectionFamily
  );
  const numberOfParents = useSelector((state) => state.products?.numberOfParents);


const parentCover = useSelector((state) => state?.products?.riskAttributes?.find((x) => x.code === RISKS.QUQ)?.attributes?.find((y) => y.name === ATTRIBUTES.PARENTAL_COVER))
const [loader, setLoader] = useState(true);
  useEffect(() => {
    var total = protectionFamily?.length + protectionIndividual?.length;
    if(total === 8){
      setLoader(false);
    }
  },[protectionFamily, protectionIndividual])

  const riskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const getAttributes = (value) => {
    return riskAttributes?.find((x) => x.name === value);
  };

  const handleChange = (evt, instanceId) => {
    var arr = [...riskAttributes];
    var index = riskAttributes.findIndex((x) => x.instanceId === instanceId);
    arr[index] = { ...arr[index], value: evt };
    dispatch(updatedAttributes(arr, "QUQ"));
  };
  const getProtectionIndex = (val) => {
    switch (val) {
      case "Option 1":
        return 0;
      case "Option 2":
        return 1;
      case "Option 3":
        return 2;
      case "Option 4":
        return 3;
    }
  };

  const parseString = (string, name) => {
    const arr = string.split(",");
    let obj = {};
    arr.forEach((a) => {
      const val = a.split(":");
      obj[val[0]] = val[1];
    });
    return obj;
  };

  const isLoading = useSelector((state) => state?.products?.isLoading);

  const totalParents = () => {
    var total = riskAttributes?.filter((x) => x.name === ATTRIBUTES.PARENT_55 || x.name === ATTRIBUTES.PARENT_65 || x.name === ATTRIBUTES.PARENT_80)
    return total?.reduce((a, b) => a + (b?.value ?? 0), 0);
  }

  const getConstraints = () => {
    var obj = {};

    if(!protectionRequirement?.value){
      obj = {
        ['Protection Requirement']: {
          presence: { allowEmpty: false },
        },
        ['Cover Level']: {
          presence: { allowEmpty: false },
        }
      }
      return obj;
    }

    if(protectionRequirement?.value === 1){
      obj = {
        ['Cover Level']: {
          presence: { allowEmpty: false },
        },
        [getAttributes(ATTRIBUTES.CHILD_COVER)?.name]: {
          presence: { allowEmpty: false },
        },
        [getAttributes(ATTRIBUTES.SPOUSE_COVER)?.name]: {
          presence: { allowEmpty: false },
        },
       
        [getAttributes(ATTRIBUTES.PARENTAL_COVER)?.name]: {
          presence: { allowEmpty: false },
        },
      }
      if(getAttributes(ATTRIBUTES.CHILD_COVER)?.value === 0){
        obj = {
          ...obj,
          [getAttributes(ATTRIBUTES.NUMBER_OF_CHILDREN)?.name]: {
            presence: { allowEmpty: false },
            numericality: {
              greaterThan: 0
            }
          },
        }
      }
      if(getAttributes(ATTRIBUTES.PARENTAL_COVER)?.value === 0){
        obj = {
          ...obj,
          [getAttributes(ATTRIBUTES.PARENT_55)?.name]: {
            presence: { allowEmpty: false },
          },
          [getAttributes(ATTRIBUTES.PARENT_65)?.name]: {
            presence: { allowEmpty: false },
          },
          [getAttributes(ATTRIBUTES.PARENT_80)?.name]: {
            presence: { allowEmpty: false },
          },
        }
      }
      return obj;
    }
    if(protectionRequirement?.value === 0){
      obj = {
        ['Cover Level']: {
          presence: { allowEmpty: false },
        },
      }
      return obj;
    }
  }

  const [errors, setErrors] = useState({});
  const parentState = useSelector((state) => state.products?.parentAttributes);

  const getRows = (rows) => {
    var arr = [];
    for (var i = 0; i < rows; i++) {
      arr.push(i + 1);
    }
    return arr;
  };

  const handleSubmit = () => {
    try{
      if(getAttributes(ATTRIBUTES.PARENTAL_COVER)?.value === 0){
        if(totalParents() >= 5){
          return setErrors({...errors, totalParent: ['Total parents must be less than 4']})
        }else if(totalParents() <= 0){
          return setErrors({...errors, totalParent: ['Total parents must be greater than 0']})
        }
      }
     
      const errorss = validate({
        'Protection Requirement': protectionRequirement?.value,
        'Cover Level': getAttributes(ATTRIBUTES.COVER_LEVEL)?.value,
        [getAttributes(ATTRIBUTES.CHILD_COVER)?.name]: getAttributes(ATTRIBUTES.CHILD_COVER)?.value,
        [getAttributes(ATTRIBUTES.SPOUSE_COVER)?.name]: getAttributes(ATTRIBUTES.SPOUSE_COVER)?.value,
        [getAttributes(ATTRIBUTES.PARENTAL_COVER)?.name]: getAttributes(ATTRIBUTES.PARENTAL_COVER)?.value,
        [getAttributes(ATTRIBUTES.PARENT_55)?.name]: getAttributes(ATTRIBUTES.PARENT_55)?.value ?? 0,
        [getAttributes(ATTRIBUTES.PARENT_65)?.name]: getAttributes(ATTRIBUTES.PARENT_65)?.value ?? 0,
        [getAttributes(ATTRIBUTES.PARENT_80)?.name]: getAttributes(ATTRIBUTES.PARENT_80)?.value ?? 0,
        [getAttributes(ATTRIBUTES.NUMBER_OF_CHILDREN)?.name]: getAttributes(ATTRIBUTES.NUMBER_OF_CHILDREN)?.value ?? 0,
      }, getConstraints())
      if (errorss || errorss !== undefined) {
        var errorArray = Object.keys(errorss);
        document.getElementById(errorArray[0]).scrollIntoView({
          behavior: 'smooth'
        })
        setErrors(errorss);
        return;
      }else{
        setErrors({})
      }
      var arr = [];
      var relationships = state?.find((x) => x.code === 'PAL')?.attributes?.find((y) => y.name === 'Relationship');
      if(totalParents()){
        getRows(totalParents()).map((x, i) => {
          arr.push({...parentState[i], Relationship: JSON.parse(relationships.settings)?.items[0]})
        })
      }
      dispatch(
        updateCustomAttributes({
          type: "PARENT_ATTRIBUTES",
          payload: arr,
        })
      );
      handleNext();
    }
    catch(err){
      console.log(err);
    }
  }

   const handleBackTrack = () => {
     history("/");
     //trackUserEvents
     trackUserEvents("gh_insurance_selectedOption_back_cta", {});
   };

  const rates = () => {
    if(protectionRequirement?.value === 0){
      var val = protectionIndividual?.length ? parseString(protectionIndividual[0]?.risks[0]?.rating?.message) : {}
      return val;
    }else if(protectionRequirement?.value === 1){
      const index = getProtectionIndex(riskAttributes?.find((x) => x.name === ATTRIBUTES.COVER_LEVEL)?.value);
      var val = protectionFamily?.length ? parseString(protectionFamily[index]?.risks[0]?.rating?.message) : {}
      return val;
    }
  }

  const premiumAmount = () => {
      var total = protectionRequirement?.value === 0
      ? (protectionIndividual[
          getProtectionIndex(
            getAttributes(ATTRIBUTES.COVER_LEVEL)?.value
          )
        ]?.risks
        ? protectionIndividual[
            getProtectionIndex(
              getAttributes(ATTRIBUTES.COVER_LEVEL)?.value
            )
          ]?.risks[0]?.rating?.total
        : 0)
      :  protectionRequirement?.value === 1 ? (protectionFamily[
          getProtectionIndex(
            getAttributes(ATTRIBUTES.COVER_LEVEL)?.value
          )
        ]?.risks
      ? protectionFamily[
          getProtectionIndex(
            getAttributes(ATTRIBUTES.COVER_LEVEL)?.value
          )
        ]?.risks[0]?.rating?.total
      : 0):0
 
        if(protectionRequirement?.value === 0 ){
        
          return total;
        }else if(
          protectionRequirement?.value === 1
        ){

          var childRate = riskAttributes?.find((x) => x.name === ATTRIBUTES.CHILD_COVER)?.value ? 0 : riskAttributes?.find((x) => x.name === ATTRIBUTES.NUMBER_OF_CHILDREN)?.value >= 5 ? ((rates()[' ChildRate '] * riskAttributes?.find((x) => x.name === ATTRIBUTES.NUMBER_OF_CHILDREN)?.value) - ((rates()[' ChildRate ']*4))) : 0;
          var parent55 = riskAttributes?.find((x) => x.name === ATTRIBUTES.PARENTAL_COVER)?.value ? 0 : riskAttributes?.find((x) => x.name === ATTRIBUTES.PARENT_55)?.value ? riskAttributes?.find((x) => x.name === ATTRIBUTES.PARENT_55)?.value * (rates()[' parentRate55 '] ?? 0) : 0 ;
          var parent65 = riskAttributes?.find((x) => x.name === ATTRIBUTES.PARENTAL_COVER)?.value ? 0 : riskAttributes?.find((x) => x.name === ATTRIBUTES.PARENT_65)?.value ? riskAttributes?.find((x) => x.name === ATTRIBUTES.PARENT_65)?.value * (rates()[' parentRate65 '] ?? 0) : 0 ;
          var parent80 = riskAttributes?.find((x) => x.name === ATTRIBUTES.PARENTAL_COVER)?.value ? 0 : riskAttributes?.find((x) => x.name === ATTRIBUTES.PARENT_80)?.value ? riskAttributes?.find((x) => x.name === ATTRIBUTES.PARENT_80)?.value * (rates()[' parentRate80 '] ?? 0) : 0 ;
          var t = +total + +childRate + +parent55 + +parent65 + +parent80;
         
          return t;
        }
  }

  useEffect(() => {
    dispatch(updateCustomAttributes({
      type: 'PREMIUM',
      payload: premiumAmount()
    }));
  },[riskAttributes])

  const calculatorResponse = useSelector((state) => state?.products?.executeCalculator);

  const selectedOption = useSelector((state) => state?.products?.selectedOption);

  const coverAmount = useSelector((state) => state?.products?.riskAttributes?.find((y) => y.code === 'QUQ')?.attributes?.find((x) => x.name === 'Cover Amount')?.value);
  
   const decimalCheck = (string) => {
    if (string.split(".").length <= 1) {
      return `${string}`;
    } else  if(string.split(".")[1].length <= 1){
      return `${string}0`;
    }else {
      return string;
    }
  };

  const getProductOptions = () => {
    var arr = [
      {
        ...calculatorResponse?.productOptions?.[0],
        name: 'Family Care Package',
        rate: calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating?.message.split(' ')[4]
      },
      {
        ...calculatorResponse?.productOptions?.[0],
        name: 'Family Care with 1st Anniversary Benefit',
        subname:'Family Care Plus adds 50% of the funeral benefit, to be paid one(1) year after the death',
        rate: calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating?.message.split(' ')[6]
      }
    ]    
    return arr;
  }
  const premiumAmounts = getProductOptions()?.map((item) => parseFloat(item?.rate)*parseInt(coverAmount));
  return (riskAttributes?.length) ? (
    <div className="mt-4 h-100">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Family Care Assurance</h3>
            <h2 className="primary-color f-800">Indicative Quote</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Family Care Assurance</p>
          <h2 className="primary-color f-800">Indicative Quote</h2>
        </div>
      )}
      

      <div className="d-flex justify-content-center flex-column align-items-center mt-5">
        {getProductOptions()?.map((item) => <div className={style.product_card}>
          <h5 className="text-white text-center f-700">{item?.name ?? ''}</h5>
          <span style={{color: '#FBD105',fontSize:"16px"}}>{item?.subname ?? ''}</span>
          <div className={classNames(style.product_card_sub, 'mt-3')}>
            <h6 style={{color: '#FBD105'}}>Premium</h6>
            <div className="d-flex justify-content-between">
              <h6 style={{color: '#3C4096'}}>Monthly cost</h6>
              <h6 style={{color: '#3C4096'}}>GHS {(parseFloat(item?.rate)*parseInt(coverAmount))?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0.00}</h6>
            </div>
            <h6 className="mt-4"  style={{color: '#FBD105'}}>Level of Cover</h6>
            <div className="d-flex justify-content-between">
              <h6 style={{color: '#3C4096'}}>Main member</h6>
              <h6  style={{color: '#3C4096'}}>GHS {(Number.isInteger(parseFloat(coverAmount)) ? parseInt(coverAmount)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : parseFloat(coverAmount)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))}</h6>
            </div>
            <div className="d-flex justify-content-between">
              <h6  style={{color: '#3C4096'}}>Spouse</h6>
              <h6  style={{color: '#3C4096'}}>GHS  {(Number.isInteger(parseFloat(coverAmount)) ? parseInt(coverAmount)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : parseFloat(coverAmount)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))}</h6>
            </div>
            <div className="d-flex justify-content-between">
              <h6  style={{color: '#3C4096'}}>Children</h6>
              <h6 style={{color: '#3C4096'}}>GHS  {(Number.isInteger(parseFloat(coverAmount)/2) ? (parseInt(coverAmount)/2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (parseFloat(coverAmount)/2)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))}</h6>
            </div>
            <div className="d-flex justify-content-between">
              <h6 style={{color: '#3C4096'}}>Extended family</h6>
              <h6 style={{color: '#3C4096'}}>GHS   {(Number.isInteger(parseFloat(coverAmount)/2) ? (parseInt(coverAmount)/2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (parseFloat(coverAmount)/2)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))}</h6>
            </div>
            
          </div>
          <button className={selectedOption?.name === item.name ? style.product_card_button_selected : {}} onClick={() => {
            dispatch(
              updateCustomAttributes({
                type: "SELECTED_OPTION",
                payload: item,
              })
            );
            //trackUserEvents
            trackUserEvents("gh_insurance_indicative_quote__cta", {
              selectedOption: item.name,
            });
            handleNext();
          }}>
           {selectedOption?.name === item.name ? 'Selected' : 'Get Insured'}
          </button>
        </div>)}
      </div>
      

        
      <div style={{ height: "50px" }} />
      <NavigationButtons 
        back={handleBackTrack}
      isNextDisabled = {premiumAmounts[0] === 0 || premiumAmounts[1] === 0 || premiumAmounts[0] === 0.00 || premiumAmounts[1] === 0.00}
      next={() => {
        if (!Object.keys(selectedOption || {})?.length) {
          //trackUserEvents
          trackUserEvents("gh_insurance_indicative_quote__cta", {});
          return toast?.error("Please select any of the options to continue !");
        }
        //trackUserEvents
        trackUserEvents("gh_insurance_indicative_quote_next_cta", {});
        handleNext();
      }} />
    </div>
  ) : (
    <Loader />
  );
};

const dropdownData = [
  {
    name: "0",
    value: 0,
  },
  {
    name: "1",
    value: 1,
  },
  {
    name: "2",
    value: 2,
  },
  {
    name: "3",
    value: 3,
  },
  {
    name: "4",
    value: 4,
  },
  {
    name: "5",
    value: 5,
  },
  {
    name: "6",
    value: 6,
  },
  {
    name: "7",
    value: 7,
  },
  {
    name: "8",
    value: 8,
  },
  {
    name: "9",
    value: 9,
  },
  {
    name: "10",
    value: 10,
  },
];

const dropdownDataParent = [
  {
    name: "0",
    value: 0,
  },
  {
    name: "1",
    value: 1,
  },
  {
    name: "2",
    value: 2,
  },
  {
    name: "3",
    value: 3,
  },
  {
    name: "4",
    value: 4,
  },
];

export default Step1;
