/* eslint-disable */

// <----- API URL  -------> 
export const APIUrl = {
    login: '/lg/authorisation/login',
};

// <----- Environment types  -------> 
export const ENV_TYPE = {
    DEV: 'dev',
    PROD: 'prod',
    UAT: 'uat'
};

// <----- Environments  -------> 
export const ENV = {
    ['dev']: 'https://briisk-i-dev-api.azure-api.net',
    ['uat']: 'https://briisk-i-uat-api.azure-api.net',
    ['prod']: 'https://briisk-i-prod-api.azure-api.net',
};


export const OCP_APIM_SUBSCRIPTION_KEY = {
    ['dev']: 'ec2659d3859d421d801776e4efad5780',
    ['uat']: '3296a582dbb540b8ae4843332c638a3e',
    ['prod']: 'acbdbdc1ee824a2eba5e8ba3a357ade7'
}
 
export const PAYMENT_SUBSCRIPTION_KEY = {
  //   ["prod"]: "d38a7e356be74cf88bea1782313ed66d", //old
  ["dev"]: "20ecf8f763c14d7b94f89300c44d1fee",
  ["uat"]: "718f9d21436e4c759b4571c5e0358944",
  ["prod"]: "e3df319f368e446d9cfde10e8661acc3",
};

export const PRODUCT_INSTANCE_ID = { 
    ['dev']: 'bf51bd4c-c735-40ec-b8c6-3d410eea0e88',
    ['uat']: '42174780-44cf-4217-a1c6-705c470fefd5', // Same as for DEV
    ['prod']: '7df156c2-acef-40d0-bd77-c0a126970b86'  
}
 


export const BANK_BRANCH_GUID = {
    ['dev']: 'F5D22457-30FC-8FC4-90DB-BC43DC258464',
    ['uat']: '201413A3-4410-B6C1-498E-7FD5D7100874',
    ['prod']: '7b9d8f0d-62fe-cf02-d6cf-0e5dafccef01'
}

export const MIDDLEWARE_URL = {
    ['dev']: 'https://react-middleware-dev.azurewebsites.net', 
    // ['dev']: 'http://localhost:5000',
    ['uat']: 'https://react-middleware-uat.azurewebsites.net',
    ['prod']: 'https://react-middleware-prod.azurewebsites.net',
    // ['prod']: 'http://localhost:8000',
}

/* eslint-disable */

