import classNames from "classnames";
import React, {useState } from "react";
import { Dropdown, TwoButtons, NumberInput } from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import useWindowDimensions from "../../../utils/useWindowDimension";
import { useDispatch, useSelector } from "react-redux";
import style from "../Form.module.css";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import {
  executeCalculator,
  updateCustomAttributes,
  updatedAttributes,
} from "../../../store/actions/products";
import { validate } from "validate.js";
import { useNavigate } from "react-router";
import { textAlign } from "@mui/system";
import { calculateAge60, trackUserEvents } from "../../../utils/utils";

const Step2 = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const history = useNavigate();
  const [errors, setErrors] = useState({});

  const riskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );
  const getAttributes = (value) => {
    return riskAttributes?.find((x) => x.name === value);
  };

  const handleChange = (evt, instanceId) => {
    var arr = [...riskAttributes];
    var index = riskAttributes.findIndex((x) => x.instanceId === instanceId);
    arr[index] = { ...arr[index], value: evt };
    dispatch(updatedAttributes(arr, "QUQ"));
  };

  const calculatorResponse = useSelector(
    (state) => state?.products?.executeCalculator
  );

  const selectedOption = useSelector(
    (state) => state?.products?.selectedOption
  );

  const spouseCover = useSelector(
    (state) => state?.products?.customAttributes?.spouseCover
  );

  const customAttributes = useSelector(
    (state) => state?.products?.customAttributes
  );

  const reduxState = useSelector((state) => state);
  const state = useSelector((state) => state?.products.riskAttributes);

  const coverAmount = useSelector(
    (state) =>
      state?.products?.riskAttributes
        ?.find((y) => y.code === "QUQ")
        ?.attributes?.find((x) => x.name === "Cover Amount")?.value
  );
  const [isLoading, setIsLoading] = useState(false);

  const getConstraints = () => {
    var val = {}
    val = {
      ...val,
      'Spouse Cover': {
        presence: {
          allowEmpty: false, 
        },
      },
      'Child Cover': {
        presence: {
          allowEmpty: false, 
        },
      },  
      [getAttributes(ATTRIBUTES.EXTENTED_FAMILY_COVER)?.name]: {
        presence: {
          allowEmpty: false, 
        },
      },
    }
    if(customAttributes?.childCover === 'Yes'){
      val = {
        ...val,
        'Number of Childrens': {
          presence: {
            allowEmpty: false, 
            message:"^Number of children can't be blank"
          },
          numericality: {
            greaterThan: 0,
            lessThanOrEqualTo: 4
          },
        }
      }
    }
    if(getAttributes(ATTRIBUTES.EXTENTED_FAMILY_COVER)?.value === "Yes"){
      val = {
        ...val,
        'Number of Extended family': {
          presence: {
            allowEmpty: false, 
            message:"^Number of extended family members can't be blank"
          },
          numericality: {
            greaterThan: 0,
            lessThanOrEqualTo: 6
          },
        }
      }
    }
    return val;

  }
    
    const handleSubmit = async() => {
    try {
      const errors = validate(
        {
          "Spouse Cover": spouseCover,
          "Child Cover": customAttributes?.childCover,
          "Number of Childrens": customAttributes?.numberOfChildrens,
          "Number of Extended family": customAttributes?.numberOfExtendedFamily,
          [getAttributes(ATTRIBUTES.EXTENTED_FAMILY_COVER)?.name]:
            getAttributes(ATTRIBUTES.EXTENTED_FAMILY_COVER)?.value,
        },
        getConstraints()
      );

      if (errors || errors !== undefined) {
        var errorArray = Object.keys(errors);
        document.getElementById(errorArray[0]).scrollIntoView({
          behavior: "smooth",
        });
        setErrors(errors);
        return;
      } else {
        setErrors({});
      }
      setIsLoading(true);
      await dispatch(
        executeCalculator({
          payload: {
            factors: [],
            productTypeReference:
              reduxState?.products?.types?.types[0]?.instanceId,
            risks: [
              {
                attributes: [
                  ...state
                    .find((x) => x.code === RISKS.QUQ)
                    ?.attributes.map((y) => ({
                      dataType: y.dataType,
                      name: y.name,
                      instanceId: y.instanceId,
                      value:
                        y.name === "Package"
                          ? selectedOption?.name.includes("Anniversary")
                            ? "Family Care with 1st Anniversary Benefit"
                            : "Family Care"
                          : y.name === "Cover Amount"
                          ? coverAmount
                          : y.name === "Cover Option"
                          ? selectedOption?.name.includes("Anniversary")
                            ? "Family Care with 1st Anniversary Benefit"
                            : "Family Care"
                          : y.name === "Family Members"
                          ? state
                              .find((x) => x.code === RISKS.QUQ)
                              .attributes.find(
                                (y) => y.name === "Extended Family Cover"
                              ).value === "No"
                            ? 0
                            : customAttributes?.numberOfExtendedFamily ?? null
                          : y.value === "Yes"
                          ? "YES"
                          : "NO",
                    })),
                ],
                riskReference: state.find((x) => x.code === RISKS.QUQ)
                  ?.instanceId,
                code: state.find((x) => x.code === RISKS.QUQ)?.code,
              },
            ],
          },
          type: "post",
        })
      );
      setIsLoading(false);
      //trackUserEvents
      trackUserEvents("gh_insurance_select_additionalCover_next_cta", {
        "Spouse Cover": spouseCover ,
        "Child Cover": customAttributes?.childCover,
        "Number of Childrens": customAttributes?.numberOfChildrens ?? 0,
        "Number of Extended family": customAttributes?.numberOfExtendedFamily ?? 0,
        "Extended Family Cover": getAttributes(ATTRIBUTES.EXTENTED_FAMILY_COVER)?.value,
      });
      handleNext();
    } catch (err) {
      handleNext();
      setIsLoading(false);
    }
  };

   const handleBackTrack = () => {
     //trackUserEvents
      trackUserEvents("gh_insurance_select_additionalCover_back_cta", {});
     handleBack();
   };

  return riskAttributes?.length ? (
    <div className="mt-4 h-100">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Family Care Assurance</h3>
            <h2 className="primary-color f-800">Select additional cover</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Family Care Assurance</p>
          <h2 className="primary-color f-800">Select additional cover</h2>
        </div>
      )}

      <div className="d-flex justify-content-center align-items-center flex-column mt-5">
        <div style={{ maxWidth: "460px", width: "100%",textAlign:"center" }}>
          <h6 className="primary-color f-800">{`Selected  ${[
            selectedOption,
          ]?.map((item) => item?.name ?? "")}`}</h6>
        </div>
        <div style={{ height: "30px" }} />
        {[selectedOption]?.map((item) => (
          <div className={style.product_card}>
            <h5 className="text-white text-center f-700">{item?.name ?? ""}</h5>
            <div className={classNames(style.product_card_sub, "mt-3")}>
              <h6 style={{ color: "#FBD105" }}>Premium</h6>
              <div className="d-flex justify-content-between">
                <h6 style={{ color: "#3C4096" }}>Monthly cost</h6>
                <h6 style={{ color: "#3C4096" }}>
                  GHS{" "}
                  {(parseFloat(item?.rate) * parseInt(coverAmount))
                    ?.toFixed(2)
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0.0}
                </h6>
              </div>

              <h6 className="mt-4" style={{ color: "#FBD105" }}>
                Level of Cover
              </h6>
              <div className="d-flex justify-content-between">
                <h6 style={{ color: "#3C4096" }}>Main member</h6>
                <h6 style={{ color: "#3C4096" }}>
                  GHS{" "}
                  {(Number.isInteger(parseFloat(coverAmount)) ? parseInt(coverAmount)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : parseFloat(coverAmount)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))}
                </h6>
              </div>
              <div className="d-flex justify-content-between">
                <h6 style={{ color: "#3C4096" }}>Spouse</h6>
                <h6 style={{ color: "#3C4096" }}>
                  GHS{" "}
                  {(Number.isInteger(parseFloat(coverAmount)) ? parseInt(coverAmount)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : parseFloat(coverAmount)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))} </h6>
              </div>
              <div className="d-flex justify-content-between">
                <h6 style={{ color: "#3C4096" }}>Children</h6>
                <h6 style={{ color: "#3C4096" }}>
                  GHS{" "}
                  {(Number.isInteger(parseFloat(coverAmount)/2) ? (parseInt(coverAmount)/2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (parseFloat(coverAmount)/2)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))}
                </h6>
              </div>
              <div className="d-flex justify-content-between">
                <h6 style={{ color: "#3C4096" }}>Extended family</h6>
                <h6 style={{ color: "#3C4096" }}>
                  GHS{" "}
                  {(Number.isInteger(parseFloat(coverAmount)/2) ? (parseInt(coverAmount)/2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (parseFloat(coverAmount)/2)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))}
                </h6>
              </div>
            </div>
          </div>
        ))}

        <div style={{ height: "20px" }} />

        <TwoButtons
          id="Spouse Cover"
          title={" Do you want to add a spouse (included in package)?"}
          errors={errors?.["Spouse Cover"]}
          state={spouseCover}
          onClick={(value) => {
            dispatch(
              updateCustomAttributes({
                type: "CUSTOM_ATTRIBUTES",
                payload: { spouseCover: value },
              })
            );
          }}
          options={[
            {
              name: "Yes",
              value: "Yes",
            },
            {
              name: "No",
              value: "No",
            },
          ]}
        />

        <div style={{ height: "20px" }} />

        <TwoButtons
          id="Child Cover"
          title={
            "Do you want to add children (4 children included in package)?"
          }
          errors={errors["Child Cover"]}
          state={customAttributes?.childCover}
          onClick={(value) => {
            dispatch(
              updateCustomAttributes({
                type: "CUSTOM_ATTRIBUTES",
                payload: { childCover: value },
              })
            );
          }}
          options={[
            {
              name: "Yes",
              value: "Yes",
            },
            {
              name: "No",
              value: "No",
            },
          ]}
        />

      {customAttributes?.childCover === "Yes" && (
          <div className={classNames(style.twoButtonContainer, 'p-3')} id={"Number of Childrens"} >
          <p className="primary-color f-700">{'How many children do you want to cover?'}</p>            
          <NumberInput
            half={true}
            type="number"
            customWidth={200}
            id={"Number of Childrens"}
            errors={Object.keys(errors) ? errors["Number of Childrens"] : []}
            value={customAttributes?.numberOfChildrens}
            onChange={(e) => {
              const {value} = e?.target;
              dispatch(
                updateCustomAttributes({
                  type: "CUSTOM_ATTRIBUTES",
                  payload: { 
                    ...customAttributes,
                    numberOfChildrens: value
                  },
                })
              );
            }}
          />
        </div>
        )}


        <div style={{ height: "20px" }} />

        <TwoButtons
          id={getAttributes(ATTRIBUTES.EXTENTED_FAMILY_COVER)?.name}
          title={getAttributes(ATTRIBUTES.EXTENTED_FAMILY_COVER)?.description}
          errors={errors[getAttributes(ATTRIBUTES.EXTENTED_FAMILY_COVER)?.name]}
          state={getAttributes(ATTRIBUTES.EXTENTED_FAMILY_COVER)?.value}
          onClick={(value) => {
            handleChange(
              value,
              getAttributes(ATTRIBUTES.EXTENTED_FAMILY_COVER)?.instanceId
            );
          }}
          options={[
            {
              name: "Yes",
              value: "Yes",
            },
            {
              name: "No",
              value: "No",
            },
          ]}
        />
        {getAttributes(ATTRIBUTES.EXTENTED_FAMILY_COVER)?.value === "Yes" && (
          <div className={classNames(style.twoButtonContainer, 'p-3')} id={"Number of Extended family"} >
          <p className="primary-color f-700">{"How many additional family members do you want to cover?"}</p>                      
          <NumberInput
            half={true}
            type="number"
            id={"Number of Extended family"}
            customWidth={200}
            errors={
              Object.keys(errors) ? errors["Number of Extended family"] : []
            }
            value={customAttributes?.numberOfExtendedFamily}
            onChange={(e) => {
              const {value} = e?.target;
              dispatch(
                updateCustomAttributes({
                  type: "CUSTOM_ATTRIBUTES",
                  payload: { numberOfExtendedFamily: value },
                })
              );
            }}
          />
         </div>
        )}
        <div style={{ height: "20px" }} />

      </div>

      <div style={{ height: "20px" }} />

      <NavigationButtons
        back={handleBackTrack}
        loading={isLoading}
        isBackDisabled={isLoading}
        next={() => handleSubmit()}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )} 
    </div>
  ) : (
    <Loader />
  );
};

const childData = [
  {
    name: "1",
    value: 1,
  },
  {
    name: "2",
    value: 2,
  },
  {
    name: "3",
    value: 3,
  },
  {
    name: "4",
    value: 4,
  },
];

const familyData = [
  {
    name: "1",
    value: 1,
  },
  {
    name: "2",
    value: 2,
  },
  {
    name: "3",
    value: 3,
  },
  {
    name: "4",
    value: 4,
  },
  {
    name: "5",
    value: 5,
  },
  {
    name: "6",
    value: 6,
  },
];

export default Step2;
