// initial state of the reducer
const initialState = {
   
};


  // function to update state
  function common(state = initialState, action) {
    // destructuring the action from function
    const { type, payload } = action;
  
    // switch to match type and update payload
    switch (type) {
     
      // return default state if the type doesnt match any case
      default:
        return state;
    }
  }
  
  export default common;
  