import { Accordion, Divider } from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { DateInput, TextInput } from "../../../components";
import Summary from "../../../components/Forms/Summary";
import NavigationButtons from "../../../components/NavigationButtons";
import { storeState } from "../../../store/actions/prevState";
import {
  getProductPayment,
  generateCheckout,
  getPaymentStatus,
  issuePolicy,
  addPolicyBankDetails,
  approvePolicy,
  sendOTP,
  validateOTP,
} from "../../../store/actions/products";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import useWindowDimensions from "../../../utils/useWindowDimension";
import style from "../Form.module.css";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { validate } from "validate.js";
import { Col, Row } from "react-bootstrap";
import Loader from '../../../components/Loader';
import { calculateAge60, trackUserEvents } from "../../../utils/utils";

const Step5 = ({ handleNext, handleBack }) => {
  const [attributes, setAttributes] = useState({});
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setFormIsLoading] = useState(false);
  const premiumAmount = useSelector((state) => state?.products?.premium);
  const policy = useSelector((state) => state?.products?.policy);
  const mainMember = useSelector((state) => state?.products?.mainMember);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [generateForm, setGenerateForm] = useState("");
  const [date, setDate] = useState();
  const [terms, setTerms] = useState({
    aggreement: null,
    terms: false,
    declaration: false,
  });

  const riskAttributes = useSelector(
    (state) => state?.products?.riskAttributes
  );

  const moment = require("moment");



  const handleChange = (evt) => {
    setAttributes({
      ...attributes,
      [evt.target.name]: evt.target.value,
    });
  };

  const otpResponse = useSelector((state) => state?.products?.otpResponse);

  useEffect(() => {
    var date = new Date().toJSON().slice(0, 10);
    setDate(date)
  },[])

  const handleRequestOTP = async () => {
    setIsOTPSent(true);
    await dispatch(sendOTP())
      .then((res) => {})
      .catch((err) => toast.error(err?.response?.data?.message));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res1 = await dispatch(
        validateOTP({
          key: otpResponse?.key,
          password: attributes?.otp,
        })
      );

      if (res1) {
        //----ghana payment methods calls start-----////
        const product_Payment = await dispatch(getProductPayment());
        if (product_Payment) {
          const data = {
            collectionDay: date.split("-")[2],
            collectionDuration: 0,
            collectionFrequency: "3",
            paymentInstanceId:
              product_Payment.productPayment[0].configurationKey,
            companyId: product_Payment.companyInstanceId,
            policyReferenceId: policy && policy?.policies[0]?.policyReference,
            policySaleReference: policy?.policySaleReference,
            amount: selectedOptions?.risks?.[0]?.rating?.total,
            currency: "GHS",
            checkoutType: 1,
            shopperSuccessUrl: `${window.location.origin}/payment-success`,
            shopperFailureUrl: `${window.location.origin}/payment-failure`,
          };
          const generate_Checkout = await dispatch(generateCheckout(data));
          if (generate_Checkout?.data?.data?.form) {
            dispatch(
              storeState({ generateCheckout: generate_Checkout?.data?.data })
            );
            setIsForm(true);
            setGenerateForm(generate_Checkout?.data?.data?.form);
          }
        }
        //---ghana payment methods calls ends -----////
        //trackUserEvents
        trackUserEvents("gh_application_payment_details_confirm_cta", {});
        setIsLoading(false);
        dispatch(storeState());
      } else {
        toast.error("Please enter valid OTP");
        //trackUserEvents
        trackUserEvents("gh_application_payment_details_confirm_cta", {});
        setIsLoading(false);
      }
    } catch (err) {
      toast.error("Please enter valid OTP");
      setIsLoading(false);
    }
  };

   const handleBackTrack = () => {
     //trackUserEvents
     trackUserEvents("gh_application_payment_details_back_cta", {});
     handleBack();
   };


  const selectedOptions = useSelector(
    (state) => state?.products?.executeCalculator2?.productOptions?.[0]
  );

  const paymentScript = useSelector(
    (state) => state?.prevState?.generateCheckout?.script
  );
  useEffect(() => {
    if(paymentScript){
      let myScript = paymentScript;
      myScript = myScript.replace("<script>", "");
      myScript = myScript.replace("</script>", "");
      const script = document.createElement("script");
  
      script.innerHTML = myScript;
  
      document.body.appendChild(script);
    }
  },[paymentScript])

  function clickHandler(e) {
    const el = e.target.closest('div > form > table > tbody > tr > td > input');
    const er = document.getElementsByTagName('input')[0]?.value;
    if ((el && e.currentTarget.contains(el)) && er?.length) {
      //console.log('hola');
      setFormIsLoading(true);
    }
  }

  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Family Care Assurance</h3>
            <h2 className="primary-color f-800">Complete your details</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Family Care Assurance</p>
          <h2 className="primary-color f-800">Complete your details</h2>
        </div>
      )}

      <div className="mt-4 h-100">
        <div className="d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
          {isForm ? (
            isForm && (
              <>
                <Row className={classNames("mt-5")}>
                  {/* <div dangerouslySetInnerHTML={{ __html: generateForm }} onClick={clickHandler} /> */}
                  <div dangerouslySetInnerHTML={{ __html: generateForm }}/>
                </Row>
              </>
            )
          ) : (
            <>
              <Summary
                isSummary={true}
                title={selectedOptions?.name}
                referenceNumber={
                  policy?.policies && policy?.policies[0]?.policyReference
                }
                policyHolder={mainMember?.firstName ?? "NA"}
                policyType={
                  riskAttributes
                    ?.find((y) => y.code === RISKS.UQQ)
                    ?.attributes?.find(
                      (x) => x.name === ATTRIBUTES.LIVES_COVERED
                    )?.value
                }
                coverType={
                  riskAttributes
                    ?.find((y) => y.code === RISKS.UQQ)
                    ?.attributes?.find(
                      (x) => x.name === ATTRIBUTES.COVER_OPTIONS
                    )?.value
                }
                policyHolderSurname={mainMember?.lastName ?? "NA"}
                familyPolicy={selectedOptions?.name}
                coverAmount={
                  riskAttributes
                    ?.find((y) => y.code === "QUQ")
                    ?.attributes?.find((x) => x.name === "Cover Amount")
                    ?.value ?? 0
                }
                premiumAmount={selectedOptions?.risks?.[0]?.rating?.total
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
              {/* <Accordion
                defaultExpanded={true}
                style={{ maxWidth: "460px", width: "100%" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex flex-row">
                    <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                      Payment:
                    </p>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="p-0 mt-4"> */}
                <br/>
                  <div style={{ maxWidth: "460px", width: "100%" }}>
                  <div
                    style={{
                      border: "1px solid #00000030",
                      padding: 20,
                      borderRadius: 5,
                    }}
                  >
                    <h6 className="mt-3">Declarations</h6>
                    <p>
                      I, the undersigned, declare to the best of my knowledge
                      and belief that, the statements are true and complete, I
                      authorise to deduct from my Mobile Account, the monthly
                      premium and any annual increase (if selected) and pay same
                      to SAHAM Life Insurance Ghana Ltd from the date of this
                      proposal and there after monthly throughout the policy
                      tenure, by requesting and filling in the OTP.
                    </p>

                    <Divider />

                    <button
                      className={style.requestOTP}
                      onClick={handleRequestOTP}
                    >
                      Request OTP
                    </button>
                    {isOTPSent && (
                      <>
                        <TextInput
                          id="otp"
                          label=""
                          name="otp"
                          placeholder={"Enter your OTP"}
                          value={attributes?.otp}
                          errors={errors?.otp}
                          onChange={(e) =>
                            setAttributes({
                              ...attributes,
                              otp: e.target.value,
                            })
                          }
                        />
                        <div>
                          <span style={{ fontSize: "14px" }}>
                            By entering your OTP, you accept and agree to the
                            T&C’s collections mandate and the declaration above{" "}
                          </span>
                        </div>
                      </>
                    )}
                    <div style={{ height: "20px" }} />
                    <Divider />
                    <div style={{ height: "20px" }} />
                    <button
                      className={style.confirm_purchase}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {isLoading ? (
                        <div class="lds-ring">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      ) : (
                        "Confirm & Submit"
                      )}
                    </button>
                  </div>
                  </div>
            </>
          )}
        </div>
      </div>

      <div style={{ height: "20px" }} />

      <div className={style.step_1_price_summary}>
        <div className={style.step_1_price_summary_sub}>
          <h5 className="text-white f-700">Total Monthly Premium</h5>
          <h5 className="text-white f-700">
            GHS{" "}
            {selectedOptions?.risks?.[0]?.rating?.total
              ?.toFixed(2)
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}
          </h5>
        </div>
      </div>

      <NavigationButtons
        back={handleBackTrack}
        payment={true}
        hideNext={true}
        loading={isLoading}
        isBackDisabled={isLoading}
        next={() => {
          if (!terms) {
            return toast.error("Please confirm terms & conditions");
          }
          dispatch(storeState());
          navigate("/payment-success");
        }}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}
    </div>
  );
};

const schema = {
  accountHolder: {
    presence: {
      allowEmpty: false,
    },
  },
  accountNumber: {
    presence: {
      allowEmpty: false,
    },
  },
  type: {
    presence: {
      allowEmpty: false,
    },
  },
  bank: {
    presence: {
      allowEmpty: false,
    },
  },
  branch: {
    presence: {
      allowEmpty: false,
    },
  },
  ["coverDate"]: {
    presence: {
      allowEmpty: false,
    },
  },
};

const schemaOtp = {
  otp: {
    presence: {
      allowEmpty: false,
      message: "^OTP can't be blank",
    },
  },
};

export default Step5;
