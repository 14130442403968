import classNames from "classnames";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Summary from "../../../components/Forms/Summary";
import useWindowDimensions from "../../../utils/useWindowDimension";
import style from "../Form.module.css";
import {clearState } from "../../../store/actions/products";
import {saham, sanlam} from '../../../assets/images/index';
import {getProductPayment, generateCheckout,getPaymentStatus, issuePolicy, addPolicyBankDetails, approvePolicy, rejectPolicy, sendOTP, validateOTP,} from "../../../store/actions/products";
import { storeState } from "../../../store/actions/prevState";



const Errorpage = () => {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  document.body.style = "background: #3C409610;";
  const policy = useSelector((state) => state?.prevState?.policy);
  const riskAttributes = useSelector(
    (state) => state?.prevState?.riskAttributes
  );

  const redirectURL = useSelector(
    (state) => state?.auth?.refererData?.redirectUrl
  );
  const premiumAmount = useSelector((state) => state?.prevState?.premium);
  const selectedOptions = useSelector(
    (state) => state?.prevState?.executeCalculator2?.productOptions?.[0]
  );

  const selected = useSelector((state) => state?.prevState?.selectedOption)

  const mainMember = useSelector((state) => state?.prevState?.mainMember);


useEffect(async() => {
  /////-------GetPaymentstatus call-----///
           const res = await dispatch(getPaymentStatus(policy.policies[0].policyReference,policy.policySaleReference));
           const code = res?.genericCode
           var splitCode = code.split("");
           if((splitCode[0] === '1' && splitCode[1] === '0') || (splitCode[0] === '2' && splitCode[1] === '0')){
            const approveddata = {
              policyReference: policy && policy?.policies[0]?.policyReference,
              comment : "submitted from react front end"
            }
            await dispatch(approvePolicy(approveddata)); 
           }
           else{
            const rejecteddata = {
              externalReference: "LETSHEGO_SALE",
              comment : "Rejected from Letshego website",
              policyReference: policy && policy?.policies[0]?.policyReference,
            }
            await dispatch(rejectPolicy(rejecteddata)); 
           }
},[])

  return (
    <div className="container form-container mt-lg-5 mb-5 p-0">
      <div className="d-flex justify-content-center flex-column">
        {width > 770 ? (
          <>
            {/* <------- HEADER --------> */}
            <h3 className={style.header}>
              Instant Funeral Cover in a few clicks
            </h3>
          </>
        ) : null}

        <div className={classNames("container", style.boxBorder)}>
          <div className="mt-4 h-100">
            {width > 770 ? (
              <>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <h3 className="primary-color f-700">Family Care Assurance</h3>
                  <h2 className="primary-color f-800">Your Application is pending payment</h2>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#00000020",
                  }}
                ></div>
              </>
            ) : (
              <div
                className={classNames(
                  "d-flex justify-content-start align-items-start flex-column",
                  style.headerContainer
                )}
              >
                <p className="text-white f-500 m-0 ">Family Care Assurance</p>
                <h2 className="primary-color f-800">Your Application is pending payment</h2>
              </div>
            )}
            <div className="d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
              <Summary
                isFail={true}
                title={selected?.name}
                referenceNumber={
                  policy?.policies && policy?.policies[0]?.policyReference
                }
                policyHolder={mainMember?.firstName ?? "NA"}
                policyHolderSurname={mainMember?.lastName ?? "NA"}
                familyPolicy ={selected?.name}
                premiumAmount={(selectedOptions?.risks?.[0]?.rating?.total)?.toFixed(2)
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
               <div style={{maxWidth: '460px' ,width: '100%'}} className="d-flex justify-content-center flex-column">
                <img src={sanlam} style={{width: '95px',display:"block",marginLeft:"auto",marginRight:"auto",marginTop:"10px"}} />
                <p style={{fontSize: 13, textAlign: 'center', marginTop: 5}}>
                Underwritten by Saham Insurance Company Limited (Reg. No. CS758822015), a Licensed Life Insurer and an authorised Financial Services Provider
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Errorpage;
