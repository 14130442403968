import React, { useState } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row } from "react-bootstrap";

// Import image from assets folder
import {main, FullMain, saham, sanlam } from "../../assets/images";

//   import custom component
import BodyButton from "../../components/Buttons/BodyButton";
import Card2 from "../../components/Card/Card2";
// import util to get current window dimensions
import useWindowDimensions from "../../utils/useWindowDimension";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  executeCalculator,
  getProductRisks,
  getProductRisksAttributes,
  getProductTypeDetails,
  updatedAttributes,
  clearState,
} from "../../store/actions/products";
import {
  getAgentOnboarding,
  login,
} from "../../store/actions/auth";
import { ATTRIBUTES, RISKS } from "../../utils/constants";
import Loader from "../../components/Loader";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { parseQuery, setParam, trackUserEvents } from "../../utils/utils";

// React functional component starts here..
function Homepage({ isHomeLoading }) {
  // scroll window to top of page on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const history = useNavigate();

  const agentCode = useSelector((state) => state.auth.agentCode);

  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

  const [isLoading, setIsLoading] = useState(false);

  const [homeLoading, setHomeLoading] = useState(true);


  const state = useSelector((state) => state.products.riskAttributes);

  // Risk from the Product
  const risks = useSelector((state) =>
    state.products?.risks?.risks?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
  const risksAttributes = useSelector((state) =>
    state.products?.riskAttributes?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  const productType = useSelector(
    (state) =>
      state?.products?.types?.types &&
      state?.products?.types?.types[0]?.instanceId
  );

  const reduxState = useSelector((state) => state);

  const [coverAmount, setCoverAmount] = useState("");

  useEffect(() => {
    if(!reduxState?.products?.riskAttributes?.length) return;
    var arr = [...reduxState?.products?.riskAttributes?.find((x) => x.code === 'QUQ')?.attributes]
    const index = reduxState?.products?.riskAttributes?.find((x) => x.code === 'QUQ')?.attributes?.findIndex((y) => y.name === 'Cover Amount');
    arr[index] = {...arr[index], value: coverAmount}
    dispatch(updatedAttributes(arr, 'QUQ'))
  },[coverAmount])
  const countDown = useSelector((state) => state?.products?.countDown?.count) ?? 0;

  const [errors, setErrors] = useState({
    coverAmount: []
  })
  const [isCounter, setIsCounter] = useState(false);
  let timer = null;


  const handleCalculator = async () => {
    if(parseInt(coverAmount) === 0 || coverAmount === null || coverAmount === undefined || coverAmount === ""){
      return setErrors({
        coverAmount: ["Cover amount can't be blank"]
      })
    }
    
    if(parseInt(coverAmount) < 5000 || parseInt(coverAmount) > 100000){
      return setErrors({
        coverAmount: ['Cover amount should be between from 5,000 to 100,000']
      })
    }

    try{
      setIsLoading(true);
      setIsCounter(true);
      if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentOnboarding(agentCode));
      }

      const riskRes = await dispatch(getProductRisks());
      const productTypes = await dispatch(getProductTypeDetails());
      let risksAttrs = [];
      const asyncForEach = async (arr, callback) => {
        for (let i = 0; i < arr.length; i++) {
          await callback(arr[i]);
        }
      };
      const act = async () => {
        await asyncForEach(riskRes.risks, async (risk) => {
          risksAttrs = [
            ...risksAttrs,
            await dispatch(
              getProductRisksAttributes(risk.instanceId, coverAmount)
            ),
          ];
        });
      };
      await act();
      const res = await dispatch(
        executeCalculator({
          payload: {
            factors: [],
            productTypeReference: productTypes.types[0].instanceId,
            risks: [
              {
                attributes: [
                  ...risksAttrs
                    ?.find((x) => x.code === RISKS.QUQ)
                    ?.attributes.map((y) => ({
                      dataType: y.dataType,
                      name: y.name,
                      instanceId: y.instanceId,
                      value: y.name === "Cover Amount" ? coverAmount : null,
                    })),
                ],
                riskReference: risksAttrs.find((x) => x.code === RISKS.QUQ)
                  ?.instanceId,
                code: risksAttrs.find((x) => x.code === RISKS.QUQ)?.code,
              },
            ],
          },
          type: "pre",
        })
      );
      history("/forms");
      setIsLoading(false);
      // }
      // })

      //trackUserEvents
      trackUserEvents("gh_insurnace_get_a_quote_cta", {});
      setIsCounter(false);
      return;
    }
    catch(err){
      console.log(err);
    }
  }

    return (
    <div>
      {width > 1024 ? (
        <>
          <Container>
            <div className="homepage-banner-pattern">
              <Row className="py-3 ">
                <Col lg={4} xs={12} className="text-center main__img_container">
                  <img className="main__img" src={FullMain} alt="main" />
                </Col>
                <Col lg={8} xs={12} className="homepage__main__text">
                  <Row className="homepage1__heading1 app__header__one m-0 justify-content-center">
                    Family Care Assurance
                  </Row>
                  <Row
                    className="homepage1__heading2 m-0 pt-1 justify-content-center"
                    style={{ marginLeft: "16px" }}
                  >
                    Funeral cover to take care of the funeral expenses
                  </Row>
                  <Row className="m-0 pt-1 justify-content-center" style={{ marginLeft: "16px", color:"#FBD105",fontWeight:"bold" }}>
                  Fill in the form and get a quote now!
                </Row>
                  <Row className="py-4 m-0 justify-content-center">
                    <Col xs={6} md={4} className="p-0">
                      {width > 700 && (
                        <a
                          rel="noreferrer"
                          style={{ textDecoration: "none" }}
                          target="_blank"
                        ></a>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <Row className="mt-0">
              <Col sm={12}>
                <Card2 width={width} />
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <div className="homepage-banner-pattern">
          <Row
            className="py-3 justify-content-center"
            style={{ margin: "15px" }}
          >
            <Col lg={7} xs={12} className="homepage__main__text">
              <Row className="homepage1__heading1 app__header__one m-0 justify-content-center">
                Family Care Assurance
              </Row>
              <Row
                className="homepage1__heading2 m-0 pt-1 justify-content-center"
                style={{ marginLeft: "16px" }}
              >
                Funeral cover to take care of the funeral expenses
              </Row>
            </Col>
            <Col
              lg={5}
              xs={12}
              className="text-center main__img_container"
              style={{ marginBottom: "30px" }}
            >
              <img className="main__img" src={FullMain} alt="main" />
            </Col>
            <Row
              className="justify-content-center"
              style={{ backgroundColor: "#59599A", borderRadius: "5px" }}
            >
              <Col lg={7} xs={12} className="homepage__main__text">
                <Row className="py-4 m-0 justify-content-center">
                  <Col
                    xs={6}
                    md={4}
                    className="p-0"
                    style={{ position: "absolute", marginTop: "-45px" }}
                  >
                    {width < 700 && (
                      <a
                        rel="noreferrer"
                        style={{ textDecoration: "none" }}
                        target="_blank"
                      >
                        {/* <BodyButton
                          color="#2F2E80"
                          bg={width < 770 ? "#FBD105" : "#2F2E80"}
                          name={width < 770 ? "Buy Cover Now" : "Buy Cover Now"}
                          onClick={() => history("/forms")}
                          size={16}
                          rounded={true}
                          weight={700}
                          marginT={0}
                          marginR={width < 770 ? 0 : 50}
                          borderTRR={15}
                          borderBLR={15}
                          borderBRR={15}
                          borderTLR={15}
                          width={width < 770 ? "auto" : "100%"}
                          align={width < 770 ? "left" : "center"}
                          black={width < 430 ? false : true}
                          br={100}
                          isHeader={true}
                          link="/forms"
                        /> */}
                      </a>
                    )}
                  </Col>
                </Row>
              </Col>

              <Row style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                
                  <Row>
                  <h4
                  style={{
                    fontSize: "20px",
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    marginLeft: "5%",
                  }}
                >
                   Family Care Assurance
                  </h4>     
                  </Row>
                  <Row style={{marginBottom:"10px"}}>
                  <h4
                    style={{
                      fontSize: "10pt",
                      textAlign: "center",
                      color: "white",
                      fontWeight:"bold",
                      marginBottom:"5px",
                      marginLeft: "5%",
                    }}
                  >
                   2 cover options to choose from<br/>

                  </h4>
                </Row>
              <Row
                className="d-flex justify-content-center align-items-center"
               
              >
                  <div className="whiteoverlay_card justify-content-center p-1">
                    <h2 className="mt-2" style={{fontSize:"16px",fontWeight:"bold",textAlign:"center"}}>Add how much cover you need?</h2>
                    <form className="d-flex justify-content-center flex-column">
                      <input
                        className="w-100"
                        type="text"
                        onChange={(e) => {
                          setCoverAmount(isNaN(Number(e.target.value.charAt(e.target.value.length-1))) ? e.target.value.replace(e.target.value.charAt(e.target.value.length-1),"") : e.target.value)
                        }
                      }                       
                        placeholder="Eg: GHS 5,000 up to max 100,000"
                        style={{ borderRadius: "6px", border: "none",fontSize:"14px" }}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        value={coverAmount}
                      />
                      <span className="errors text-white">{errors?.coverAmount?.[0]}</span>
                    </form>
                    <div className="d-flex justify-content-center">
                      <button
                        onClick={handleCalculator}
                        className="yellowButton pt-2 mt-3 pb-2 pl-3 pr-3"
                      >
                        {isLoading ? 'Quoting..' : 'Get a quote'}
                      </button>
                    </div>
                  </div>
              </Row>
              </Row>
              <br/>
              <Row>
                <p
                  style={{
                    fontSize: "10pt",
                    textAlign: "center",
                    color: "white",
                    marginTop:"20%",
                    padding:"0"
                  }}
                >
                 Our <span style={{color: '#FBCF2C',fontWeight:'bold'}}> Family Package </span>includes policyholder, one spouse and a
                      maximum of four children.
                </p>
              </Row>

              <Row>
                <p
                  style={{
                    fontSize: "10pt",
                    textAlign: "center",
                    color: "white",
                    marginTop:"3px",
                    padding:"0"
                  }}
                >
                With our <span style={{color: '#FBCF2C',fontWeight:'bold'}}>Extened Family Package</span> you can add up to 6
                      additional family members.
                </p>
              </Row>

              <Row>
                <p
                  style={{
                    fontSize: "10pt",
                    textAlign: "center",
                    color: "white",
                    marginTop:"3px",
                    padding:"0"
                  }}
                >
                     <div>With benefits options from:</div>
                    <div>GHS5,000 up to GHS100,000.</div>
                </p>
              </Row>

              <Row>
                <p
                  style={{
                    fontSize: "10pt",
                    textAlign: "center",
                    color: "white",
                    marginTop:"3px",
                    padding:"0"
                  }}
                >
                    All valid claims are paid in cless than 48 hours after all
                      the required claim documents have been submitted.
                </p>
              </Row>

               <hr
                  style={{
                    color: "white",
                    height: 1,
                    width: "97%",
                    margin: "auto",
                  }}
                />
            <Row
                className="justify-content-center"
                style={{
                  fontSize: "9pt",
                  color: "white",
                  textAlign: "center",
                  marginBottom: "5px",
                  padding:"14px",
                }}
              >
                By opting to buy insurance, Letshego will share the required
                information with the insurer to facilitate your policy purchase.
              </Row>
              <Row>
              <h4
                  style={{
                    color: "white",
                    fontSize: "10pt",
                    textAlign: "center", 
                    marginTop:"5px"
                  }}
                >Policy underwritten by</h4>
                </Row>
                <Row>
                <div
                  className={`d-flex ${
                    width > 770
                      ? "justify-content-start"
                      : "justify-content-center"
                  }`}
                >
                  <img
                    src={sanlam}
                    style={{
                      marginBottom:"5px",
                      width: "95px",
                    }} 
                    alt="sanlam"
                  />
                </div>
            </Row> 
            </Row>
          </Row>
        </div>
      )}
      {/* {homeLoading && (
        <div
          style={{
            height: "99vh",
            width: "100%",
            zIndex: 100,
            backgroundColor: "white",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "0px",
          }}
        >
          <Loader />
        </div>
      )} */}
      {isLoading && (
        <div
          style={{
            height: "99vh",
            width: "100%",
            zIndex: 100,
            backgroundColor: "#00000050",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "0px",
          }}
        >
          <div style={{height: 100, width: 100}}>
            <CircularProgressbar styles={buildStyles({textColor: '#fbcf2c', pathColor:'#fbcf2c'})} value={countDown} text={`${countDown}%`} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Homepage;

const data = [
  "Option 1 - KES 50,000",
  "Option 2 - KES 75,000",
  "Option 3 - KES 100,000",
  "Option 4 - KES 200,000",
];
const data2 = [
  "Accidental deaths are covered immediately for all family members.",
  "Illness related deaths attract a waiting period of three (3) months for the principal member, spouse, children, and parents.",
  "Claims are processed within 48 hours",
];
