import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classNames from "classnames";
import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import moment from "moment";
import {
  DateInput,
  Info,
  NumberInput,
  CountryInput,
  Select,
  TextInput,
} from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import {
  updateCustomAttributes,
  updatedAttributes,
  CreatePolicy,
  getProductPayment,
  generateCheckout,
  updateAccordion,
  clearState,
  approvePolicy,
  issuePolicy
} from "../../../store/actions/products";
import useWindowDimensions from "../../../utils/useWindowDimension";
import style from "../Form.module.css";
import ReactSelect from "react-select";
import { ATTRIBUTES } from "../../../utils/constants";
import { v4 as uuidv4 } from "uuid";
import { validate } from "validate.js";
import { Schema } from "@mui/icons-material";
import Loader from "../../../components/Loader";
import { storeState } from "../../../store/actions/prevState";
import { calculateAge60, trackUserEvents } from "../../../utils/utils";

const Step4 = ({ handleNext, handleBack }) => {
  const mainMember = useSelector((state) => state?.products?.mainMember);

  const [inputs, setInputs] = useState({
    ...mainMember,
    mobilePhoneCode: "+233",
  });

  const accordionState = useSelector((state) => state?.products?.accordion);

  const { width } = useWindowDimensions();

  const premiumAmount = useSelector((state) => state?.products?.premium);

  const referralData = useSelector((state) => state?.auth?.refererData);

  const [beneCount, setBeneCount] = useState(1);
  const [isTyped, setIsTyped] = useState(false);

  const customAttributes = useSelector(
    (state) => state?.products?.customAttributes
  );

  const riskAttributes = useSelector((state) =>
    state?.products?.riskAttributes?.map((x) => ({
      ...x,
      attributes: x.attributes?.map((y) => ({ ...y, code: x.code })),
    }))
  );

  const dispatch = useDispatch();

  const terms = useSelector((state) => state?.products?.terms);

  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const quqRiskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );
  const selectedOptions = useSelector((state) => state?.products?.executeCalculator2?.productOptions?.[0]);
  const policy = useSelector((state) => state?.products?.policy);

  const [isLoading, setIsLoading] = useState(false);
  const [trustee, setTrustee] = useState(false);

  useEffect(() => {
    var divs = document.querySelectorAll("#accord-number");
    var divsArray = [].slice.call(divs);
    var displayNone = divsArray.filter(function (el) {
      return getComputedStyle(el).display === "none";
    });
    var displayShow = divsArray.filter(function (el) {
      return getComputedStyle(el).display !== "none";
    });
    var numberOfVisibleDivs = displayShow.length;
    getRows(+numberOfVisibleDivs).map((d, i) => (divs[i].innerHTML = i + 1));
  }, []);

  const getConstraints = () => {
    var val = {};

    if (inputs?.occupation) {
      val = {
        ...val,
        occupation: {
          format: {
            pattern: /^[a-zA-Z0-9 /-]+$/,
            message: "^Only alphabetic characters, number, hyphen and space are allowed",
          }
        },
      };
    }
  
    if (inputs?.address2) {
      val = {
        ...val,
        address2: {
          format: {
            pattern: /^[A-Za-z0-9, /-]+$/,
            message: "Only alphabetic characters, numbers, comma, hyphen and space are allowed",
          }
        },
      };
    }
  

    if (inputs?.postalAddress) {
      val = {
        ...val,
        postalAddress: {
          format: {
            pattern: /^[A-Za-z0-9, /-]+$/,
            message: "Only alphabetic characters, numbers, comma, hyphen and space are allowed",
          }
        },
      }
    }
  

    if (inputs?.postalCity) {
      val = {
        ...val,
        postalCity: {
          format: {
            pattern: /^[A-Za-z /-]+$/,
            message: "Only alphabetic characters, hyphen and space are allowed",
          }
        },
      }
    }
  

    if (inputs?.postalProvince) {
      val = {
        ...val,
        postalProvince: {
          format: {
            pattern: /^[A-Za-z /-]+$/,
            message: "Only alphabetic characters, hyphen and space are allowed",
          }
        },
      }
    }
  

    riskAttributes
      .find((x) => x.code === "ADQ")
      ?.attributes.forEach((y, i) => {
        if (y.name === "Medical Information" && additionalInfo["Medical Question"] === "NO") {
          val = {
            ...val,
            [y.name]: {
              presence: {
                allowEmpty: false,
                message:"^Please provide additional details"
              },
            },
          };
        }
        if (y.name === "Medical Question") {
          val = {
            ...val,
            [y.name]: {
              presence: {
                allowEmpty: false,
              },
            },
          };
        } else if (y.name === "Automatic Annual Benefit Increase") {
          val = {
            ...val,
            [y.name]: {
              presence: {
                allowEmpty: false,
              },
            },
          };
        }
      });

    if (customAttributes?.spouseCover === "Yes") {
      riskAttributes
        .find((x) => x.code === "SPO")
        ?.attributes.forEach((y, i) => {
          if (y.name === "Spouse First Name") {
            val = {
              ...val,
              [y.name]: {
                presence: {
                  allowEmpty: false,
                  message: "^ First name can't be blank"
                },
                format: {
                  pattern: /^[A-Za-z /-]+$/,
                  message: "^Only alphabetic characters, hyphen and space are allowed",
                }
              },
            };
          } else if (y.name === "Spouse Last Name") {
            val = {
              ...val,
              [y.name]: {
                presence: {
                  allowEmpty: false,
                  message: "^ Last name can't be blank"
                },
                format: {
                  pattern: /^[A-Za-z /-]+$/,
                  message: "^Only alphabetic characters, hyphen and space are allowed",
                }
              },
            };
          } else if (y.name === "Spouse DOB") {
            val = {
              ...val,
              [y.name]: {
                presence: {
                  allowEmpty: false,
                  message: "^ Date of birth can't be blank"
                },
              },
            };
          } else if (y.name === "Spouse Relationship to Main Member") {
            val = {
              ...val,
              [y.name]: {
                presence: {
                  allowEmpty: false,
                  message: "^ Relationship to Main Member can't be blank"
                },
              },
            };
          } else if (y.name === "Spouse Gender") {
            val = {
              ...val,
              [y.name]: {
                presence: {
                  allowEmpty: false,
                  message: "^ Gender can't be blank"
                },
              },
            };
          }
        });
    }
    if (
      riskAttributes
        ?.find((x) => x.code === "QUQ")
        ?.attributes?.find((z) => z.name === ATTRIBUTES.EXTENTED_FAMILY_COVER)
        ?.value === "Yes"
    ) {
      getRows(customAttributes?.numberOfExtendedFamily).map((x, i) => {
        val = {
          ...val,
          [`Extended Family First Name ${i + 1}`]: {
            presence: {
              allowEmpty: false,
              message: "^ First name can't be blank"
            },
            format: {
              pattern: /^[A-Za-z /-]+$/,
              message: "^Only alphabetic characters, hyphen and space are allowed",
            }
          },
          [`Extended Family Last Name ${i + 1}`]: {
            presence: {
              allowEmpty: false,
              message: "^ Last name can't be blank"
            },
            format: {
              pattern: /^[A-Za-z /-]+$/,
              message: "^Only alphabetic characters, hyphen and space are allowed",
            }
          },
          [`Extended Family DOB ${i + 1}`]: {
            presence: {
              allowEmpty: false,
              message: "^ Date of birth can't be blank"
            },
          },
          [`Extended Fam Relationship to Main Member ${i + 1}`]: {
            presence: {
              allowEmpty: false,
              message: "^ Relationship to Main Member can't be blank"
            },
          },
          [`Extended Family Gender ${i + 1}`]: {
            presence: {
              allowEmpty: false,
              message: "^ Gender can't be blank"
            },
          },
        };
      });
    }

    if (customAttributes?.childCover === "Yes") {
      getRows(customAttributes?.numberOfChildrens).map((x, i) => {
        val = {
          ...val,
          [`Child First Name ${i + 1}`]: {
            presence: {
              allowEmpty: false,
              message: "^ First name can't be blank"
            },
            format: {
              pattern: /^[A-Za-z /-]+$/,
              message: "^Only alphabetic characters, hyphen and space are allowed",
            }
          },
          [`Child Last Name ${i + 1}`]: {
            presence: {
              allowEmpty: false,
              message: "^ Last name can't be blank"
            },
            format: {
              pattern: /^[A-Za-z /-]+$/,
              message: "^Only alphabetic characters, hyphen and space are allowed",
            }
          },
          [`Child DOB ${i + 1}`]: {
            presence: {
              allowEmpty: false,
              message: "^ Date of birth can't be blank"
            },
          },
          [`Child Relationship to Main Member ${i + 1}`]: {
            presence: {
              allowEmpty: false,
              message: "^ Relationship to Main Member can't be blank"
            },
          },
          [`Child Gender ${i + 1}`]: {
            presence: {
              allowEmpty: false,
              message: "^ Gender can't be blank"
            },
          },
        };
      });
    }

    if (beneficiaries?.length) {
      getRows(beneCount)?.map((x, i) => {
        val =
          beneficiaries[i]?.["dob"] && calculateage(beneficiaries[i]?.["dob"])
            ? {
                ...val,
                [`Trustee First Name ${i + 1}`]: {
                  presence: {
                    allowEmpty: false,
                    message: "^ First name can't be blank"
                  },
                  format: {
                    pattern: /^[A-Za-z /-]+$/,
                    message: "^Only alphabetic characters, hyphen and space are allowed",
                  }
                },
                [`Trustee Last Name ${i + 1}`]: {
                  presence: {
                    allowEmpty: false,
                    message: "^ Last name can't be blank"
                  },
                  format: {
                    pattern: /^[A-Za-z /-]+$/,
                    message: "^Only alphabetic characters, hyphen and space are allowed",
                  }
                },
                [`Trustee Phone Number ${i + 1}`]: {
                  presence: {
                    allowEmpty: false,
                    message: "^ Mobile number can't be blank"
                  },
                  format: {
                    pattern:  /^(\+\d{1,3}[- ]?)?\d{8,12}$/,
                    message: "^ Invalid mobile number",
                  },
                },
                [`Trustee dob ${i + 1}`]: {
                  presence: {
                    allowEmpty: false,
                    message: "^ Date of birth can't be blank"
                  },
                },
                [`First name ${i + 1}`]: {
                  presence: {
                    allowEmpty: false,
                  },
                  format: {
                    pattern: /^[A-Za-z /-]+$/,
                    message: "^Only alphabetic characters, hyphen and space are allowed",
                  }
                },
                [`Last name ${i + 1}`]: {
                  presence: {
                    allowEmpty: false,
                  },
                  format: {
                    pattern: /^[A-Za-z /-]+$/,
                    message: "^Only alphabetic characters, hyphen and space are allowed",
                  }
                },
                [`Email ${i + 1}`]: {
                  presence: {
                    allowEmpty: false,
                  },
                  email: true,
                },
                [`Date of birth ${i + 1}`]: {
                  presence: {
                    allowEmpty: false,
                  },
                },
                [`Phone Number ${i + 1}`]: {
                  presence: {
                    allowEmpty: false,
                    message:`^Mobile number ${i + 1} can't be blank`
                  },
                  format: {
                    pattern: /^(\+\d{1,3}[- ]?)?\d{8,12}$/,
                    message: "^ Invalid mobile number",
                  },
                },
                [`Percentage ${i + 1}`]: {
                  presence: {
                    allowEmpty: false,
                  },
                },
              }
            : {
                ...val,
                [`First name ${i + 1}`]: {
                  presence: {
                    allowEmpty: false,
                  },
                  format: {
                    pattern: /^[A-Za-z /-]+$/,
                    message: "^Only alphabetic characters, hyphen and space are allowed",
                  }
                },
                [`Last name ${i + 1}`]: {
                  presence: {
                    allowEmpty: false,
                  },
                  format: {
                    pattern: /^[A-Za-z /-]+$/,
                    message: "^Only alphabetic characters, hyphen and space are allowed",
                  }
                },
                [`Email ${i + 1}`]: {
                  presence: {
                    allowEmpty: false,
                  },
                  email: true,
                },
                [`Date of birth ${i + 1}`]: {
                  presence: {
                    allowEmpty: false,
                  },
                },
                [`Phone Number Code ${i + 1}`]: {
                  presence: {
                    allowEmpty: false,
                  },
                },
                [`Phone Number ${i + 1}`]: {
                  presence: {
                    allowEmpty: false,
                    message:`^Mobile number ${i + 1} can't be blank`
                  },
                  format: {
                    pattern: /^(\+\d{1,3}[- ]?)?\d{8,12}$/,
                    message: "^ Invalid mobile number",
                  },
                },
                [`Percentage ${i + 1}`]: {
                  presence: {
                    allowEmpty: false,
                  },
                },
              };
      });
    }

    delete val["P.O. Box"];
    return val;
  };

  const beneficiariesState = useSelector(
    (state) => state?.products?.beneficiaries
  );

  useEffect(() => {
    setBeneficiaries(beneficiariesState);
  }, []);

  const [beneficiaries, setBeneficiaries] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      number: "",
      percentage: "",
      dob: "",
     "Phone Number Code": "+233",
    },
  ]);

  useEffect(() => {
    if (errors) {
      console.log(errors);
    }
  }, [errors]);

  const childState = useSelector((state) => state.products?.childAttributes);
  const [childAttributes, setChildAttributes] = useState(childState);

  const extendedFamilyState = useSelector(
    (state) => state.products?.extendedAttributes
  );
  const [extendedFamilyAttributes, setextendedFamilytAttributes] =
    useState(extendedFamilyState);

  const calculateage = (date) => {
    var diffInYears = moment().diff(date, "years");
    if (diffInYears < 18) {
      return true;
    }
  };

  const handleChangeBene = (evt, index) => {
    var arr = [...beneficiaries];
    arr[index] = { ...arr[index], [evt.target.name]: evt.target.value };
    setBeneficiaries(arr);
    dispatch(
      updateCustomAttributes({
        type: "BENEFICIARIES",
        payload: arr,
      })
    );
  };

  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }

  const getAttributes = (value) => {
    return quqRiskAttributes.find((x) => x.name === value);
  };

  const getRows = (rows) => {
    var arr = [];
    for (var i = 0; i < rows; i++) {
      arr.push(i + 1);
    }
    return arr;
  };

  const handleChangePolicy = (e, name) => {
    let obj = { ...inputs };
    if (name) {
      name === "Document Type"
        ? (obj = {
            ...inputs,
            [name]: e ?? "Ghana Card",
          })
        : (obj = {
            ...inputs,
            [name]: `+${e}`,
          });
    } else {
      obj = {
        ...inputs,
        [e.target.name]: e.target.value,
      };
    }
    setInputs(obj);
    dispatch(
      updateCustomAttributes({
        type: "MAIN_MEMBER",
        payload: obj,
      })
    );
  };
  const [expand, setExpand] = useState({
    policy: true,
    spouse: false,

    ...accordionState,
  });

  const calculateMinDate = (type) => {
    const date = new Date();
    if (type === "parent") {
      date.setFullYear(date.getFullYear() - 122);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === undefined) {
      date.setFullYear(date.getFullYear() - 122);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "extended") {
      date.setFullYear(date.getFullYear() - 122);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "bene") {
      date.setFullYear(date.getFullYear() - 122);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    }

    if (type === "child") {
      date.setFullYear(date.getFullYear() - 24);
    } else {
      date.setFullYear(date.getFullYear() - 80);
    }

    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const calculateMaxDate = (type) => {
    const date = new Date();
    if (type === "parent") {
      date.setFullYear(date.getFullYear() - 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === undefined) {
      // Spouse -> undefined
      date.setFullYear(date.getFullYear() - 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "extended") {
      date.setFullYear(date.getFullYear() - 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "bene") {
      date.setFullYear(date.getFullYear() - 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    }
    if (type === "child") {
      return new Date();
    } else {
      date.setFullYear(date.getFullYear() - 18);
    }

    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const calculateBeneMinDate = (type) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 100);
    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const calculateBeneMaxDate = () => {
    const date = new Date();
    return new Date();
  };

  const calculateTrusteeMaxDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const handleChange = (evt, instanceId, code) => {
    var attribute = riskAttributes.find((x) => x.code === code)?.attributes;
    var arr = [...attribute];
    var index = attribute.findIndex((x) => x.instanceId === instanceId);
    arr[index] = { ...arr[index], value: evt };
    dispatch(updatedAttributes(arr, code));
  };

  const handleCustomChange = (evt, name, index) => {
    var arr = [...childState];
    arr[index] = { ...arr[index], [name]: evt };
    setChildAttributes(arr);
    dispatch(
      updateCustomAttributes({
        type: "CHILD_ATTRIBUTES",
        payload: arr,
      })
    );
  };

  const handleCustomExtendedChange = (evt, name, index) => {
    var arr = [...extendedFamilyAttributes];
    arr[index] = { ...arr[index], [name]: evt };
    setextendedFamilytAttributes(arr);
    dispatch(
      updateCustomAttributes({
        type: "EXTENDED_FAMILY_ATTRIBUTES",
        payload: arr,
      })
    );
  };

  const additionalInfo = useSelector(
    (state) => state?.products?.additionalInfo
  );

  const [additional, setAdditional] = useState({ ...additionalInfo });

  const handleAdditionalChange = (evt, name) => {
    let obj = { ...additional };
    obj = { [name]: evt };
    setAdditional(obj);
    dispatch(
      updateCustomAttributes({
        type: "ADD_INFO",
        payload: obj,
      })
    );
  };

  const renderComponents = useCallback(
    (item, custom, index) => {
      switch (item.dataType) {
        case 1:
          return (
            <TextInput
              id={item.name}
              label={item.description}
              errors={
                custom
                  ? custom === "additional"
                    ? errors[item.name]
                    : errors[`${item.name} ${index + 1}`]
                  : errors[item.name]
              }
              name={item.name}
              value={
                custom === "child"
                  ? childState?.[index]?.[item?.name]
                  : custom === "extended"
                  ? extendedFamilyState?.[index]?.[item?.name]
                  : custom === "additional"
                  ? additional[item?.name]
                  : item?.value
              }
              onChange={(e) => {
                custom === "child"
                  ? handleCustomChange(e.target.value, item.name, index)
                  : custom === "extended"
                  ? handleCustomExtendedChange(e.target.value, item.name, index)
                  : custom === "additional"
                  ? handleAdditionalChange(e.target.value, item.name)
                  : handleChange(e.target.value, item.instanceId, item.code);
              }}
              required={item.isRequired}
            />
          );
        case 2:
          return (
            <NumberInput
              id={item.name}
              label={item.description}
              errors={custom ? "" : errors[item.name]}
              name={item.name}
              placeholder={"+254123456789"}
              value={
                custom === "child"
                  ? childState?.[index]?.[item?.name]
                  : custom === "extended"
                  ? extendedFamilyState?.[index]?.[item?.name]
                  : custom === "additional"
                  ? additional[item?.name]
                  : item?.value
              }
              onChange={(e) => {
                custom === "child"
                  ? handleCustomChange(e.target.value, item.name, index)
                  : custom === "extended"
                  ? handleCustomExtendedChange(e.target.value, item.name, index)
                  : custom === "additional"
                  ? handleAdditionalChange(e.target.value, item.name)
                  : handleChange(e.target.value, item.instanceId, item.code);
              }}
              required={item.isRequired}
            />
          );
        case 5:
          return (
            <DateInput
              id={item.name}
              label={item.description}
              errors={
                custom ? errors[`${item.name} ${index + 1}`] : errors[item.name]
              }
              name={item.name}
              minDate={calculateMinDate(custom)}
              maxDate={calculateMaxDate(custom)}
              value={
                custom === "child"
                  ? childState?.[index]?.[item?.name]
                  : custom === "extended"
                  ? extendedFamilyState?.[index]?.[item?.name]
                  : custom === "additional"
                  ? additional[item?.name]
                  : item?.value
              }
              onChange={(e) => {
                custom === "child"
                  ? handleCustomChange(e.target.value, item.name, index)
                  : custom === "extended"
                  ? handleCustomExtendedChange(e.target.value, item.name, index)
                  : custom === "additional"
                  ? handleAdditionalChange(e.target.value, item.name)
                  : handleChange(e.target.value, item.instanceId, item.code);
              }}
              required={item.isRequired}
            />
          );
        case 7:
          if (
            item.settings.includes('["Yes","No"]') ||
            item.settings.includes('["NO","YES"]')
          ) {
            return <></>;
          }
          return (
            <Select
              id={item.name}
              title={item.description}
              errors={
                custom
                  ? custom === "additional"
                    ? errors[item.name]
                    : errors[`${item.name} ${index + 1}`]
                  : errors[item.name]
              }
              half={true}
              onMouseOver={(e) => e}
              onMouseOut={(e) => e}
              name={item.name}
              width={150}
              defaultValue = {custom === "mainMem" ? "Ghana Card" : "Please Select"}
              options={custom === "mainMem" ?JSON.parse(item.settings)?.items?.filter((y) => y === "Ghana Card")?.map((x) => ({
                name: x,
                value: x,
              })) : JSON.parse(item.settings)?.items?.map((x) => ({
                name: x,
                value: x,
              }))}
              value={
                custom === "child"
                  ? childState?.[index]?.[item?.name]
                  : custom === "extended"
                  ? extendedFamilyState?.[index]?.[item?.name]
                  : custom === "additional"
                  ? additional[item?.name]
                  : item?.value ?? ""
              }
              onClick={(e) => {
                custom === "child"
                  ? handleCustomChange(e, item.name, index)
                  : custom === "extended"
                  ? handleCustomExtendedChange(e, item.name, index)
                  : custom === "additional"
                  ? handleAdditionalChange(e, item.name)
                  : custom === "mainMem"
                  ? handleChangePolicy(e, item.name)
                  : handleChange(e, item.instanceId, item.code);
              }}
              required={item.isRequired}
            />
          );
      }
    },
    [errors, riskAttributes]
  );

  const reducerState = useSelector((state) => state?.products);

  const coverAmount = useSelector(
    (state) =>
      state?.products?.riskAttributes
        ?.find((y) => y.code === "QUQ")
        ?.attributes?.find((x) => x.name === "Cover Amount")?.value
  );

  const calculatorResponse = useSelector(
    (state) => state?.products?.executeCalculator
  );

  const selectedOption = useSelector(
    (state) => state?.products?.selectedOption
  );

  const spouseCover = useSelector(
    (state) => state?.products?.customAttributes?.spouseCover
  );

  const agentOnBoarding = useSelector((state) => state?.auth?.agentOnboarding);

  useEffect(() => {
    dispatch(updateAccordion(expand));
  }, [expand]);

  const handleSubmit = async () => {
    var values = {
      ...inputs,
      maritalStatus: inputs.maritalStatus?.value,
      mainGender: inputs?.mainGender?.value,
      ["Document Type"]: inputs?.["Document Type"] ?? "Ghana Card",
    };

    riskAttributes
      ?.find((x) => x.code === "SPO")
      ?.attributes?.forEach((t) => {
        values = { ...values, [t.name]: t.value };
      });

    riskAttributes
      ?.find((x) => x.code === "ADQ")
      ?.attributes?.forEach((t) => {
        values = { ...values, [t.name]: additionalInfo[t.name] };
      });

    getRows(customAttributes?.numberOfChildrens).map((x, i) => {
      values = {
        ...values,
        [`Child First Name ${i + 1}`]: childState[i]?.[`Child First Name`],
        [`Child Last Name ${i + 1}`]: childState[i]?.[`Child Last Name`],
        [`Child Gender ${i + 1}`]: childAttributes[i]?.[`Child Gender`],
        [`Child Relationship to Main Member ${i + 1}`]:
          childState[i]?.[`Child Relationship to Main Member`],
        [`Child DOB ${i + 1}`]: childState[i]?.[`Child DOB`],
      };
    });

    getRows(customAttributes?.numberOfExtendedFamily).map((x, i) => {
      values = {
        ...values,
        [`Extended Family First Name ${i + 1}`]:
          extendedFamilyState[i]?.[`Extended Family First Name`],
        [`Extended Family Last Name ${i + 1}`]:
          extendedFamilyState[i]?.[`Extended Family Last Name`],
        [`Extended Family DOB ${i + 1}`]:
          extendedFamilyState[i]?.[`Extended Family DOB`],
        [`Extended Fam Relationship to Main Member ${i + 1}`]:
          extendedFamilyState[i]?.[`Extended Fam Relationship to Main Member`],
        [`Extended Family Gender ${i + 1}`]:
          extendedFamilyState[i]?.[`Extended Family Gender`],
      };
    });

    let beneAttributes = [];

    if (beneficiaries?.length) {
      getRows(beneCount)?.map((x, i) => {
        beneAttributes.push(`First name ${i + 1}`);
        beneAttributes.push(`Last name  ${i + 1}`);
        beneAttributes.push(`Email ${i + 1}`);
        beneAttributes.push(`Date of birth ${i + 1}`);
        beneAttributes.push(`Percentage ${i + 1}`);
        beneAttributes.push(`Trustee First Name ${i + 1}`);
        values =
          beneficiaries[i]?.["dob"] && calculateage(beneficiaries[i]?.["dob"])
            ? {
                ...values,
                [`Trustee First Name ${i + 1}`]: Object.keys(
                  beneficiaries[i]?.["dob"] ?? {}
                )?.length
                  ? beneficiaries[i]["trusteeName"]
                  : null,
                [`Trustee Last Name ${i + 1}`]: Object.keys(
                  beneficiaries[i]?.["dob"] ?? {}
                )?.length
                  ? beneficiaries[i]["trusteeLastName"]
                  : null,
                [`Trustee Phone Number ${i + 1}`]: Object.keys(
                  beneficiaries[i]?.["dob"] ?? {}
                )?.length
                  ? beneficiaries[i]["trusteeNumber"]
                  : null,
                [`Trustee dob ${i + 1}`]: Object.keys(
                  beneficiaries[i]?.["dob"] ?? {}
                )?.length
                  ? beneficiaries[i]["trusteeDob"]
                  : null,

                [`First name ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})
                  ?.length
                  ? beneficiaries[i]["firstName"]
                  : null,
                [`Last name ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})
                  ?.length
                  ? beneficiaries[i]["lastName"]
                  : null,
                [`Email ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})?.length
                  ? beneficiaries[i]["email"]
                  : null,
                [`Date of birth ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})
                  ?.length
                  ? beneficiaries[i]["dob"]
                  : null,
                  [`Phone Number Code ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})
                  ?.length
                  ? beneficiaries[i]["Phone Number Code"]
                  : null,
                [`Phone Number ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})
                  ?.length
                  ? beneficiaries[i]["number"]
                  : null,
                [`Percentage ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})
                  ?.length
                  ? beneficiaries[i]["percentage"]
                  : null,
              }
            : {
                ...values,
                [`First name ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})
                  ?.length
                  ? beneficiaries[i]["firstName"]
                  : null,
                [`Last name ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})
                  ?.length
                  ? beneficiaries[i]["lastName"]
                  : null,
                [`Email ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})?.length
                  ? beneficiaries[i]["email"]
                  : null,
                [`Date of birth ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})
                  ?.length
                  ? beneficiaries[i]["dob"]
                  : null,
                  [`Phone Number Code ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})
                  ?.length
                  ? beneficiaries[i]["Phone Number Code"]
                  : null,
                [`Phone Number ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})
                  ?.length
                  ? beneficiaries[i]["number"]
                  : null,
                [`Percentage ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})
                  ?.length
                  ? beneficiaries[i]["percentage"]
                  : null,
              };
      });
    }
    const errors = validate(values, { ...schema, ...getConstraints() });
    let err = { ...errors };

    if (err || err !== undefined) {
      Object.keys(err)?.map((x) => {
        if (Object.keys(schema)?.includes(x)) {
          err = {
            ...err,
            inputState: ["Please fill policy holder details "],
          };
        }
      });
    }

    if (err || err !== undefined) {
      if (beneficiaries?.length) {
        Object.keys(err)?.map((x) => {
          if (beneAttributes?.includes(x)) {
            err = {
              ...err,
              beneficiaries: ["Please fill beneficiaries details "],
            };
          }
        });
      }
    }

    if (err || err !== undefined) {
      if (riskAttributes?.find((x) => x.code === "ADQ")) {
        Object.keys(err)?.map((x) => {
          if (x.indexOf("Medical") === 0 || x.indexOf("Automatic") === 0) {
            err = {
              ...err,
              additionalState: ["Please fill additional details "],
            };
          }
        });
      }
    }

    if (err || err !== undefined) {
      if (customAttributes?.spouseCover === "Yes") {
        Object.keys(err)?.map((x) => {
          if (x.indexOf("Spouse") === 0) {
            err = {
              ...err,
              spouseState: ["Please fill spouse details "],
            };
          }
        });
      }
    }

    if (err || err !== undefined) {
      if (customAttributes?.numberOfChildrens) {
        Object.keys(err)?.map((x) => {
          if (x.indexOf("Child") === 0) {
            err = {
              ...err,
              childState: ["Please fill child details "],
            };
          }
        });
      }
    }

    if (err || err !== undefined) {
      if (customAttributes?.numberOfExtendedFamily) {
        Object.keys(err)?.map((x) => {
          if (x.indexOf("Extended") === 0) {
            err = {
              ...err,
              extendedFamilyState: ["Please fill extended details "],
            };
          }
        });
      }
    }


    if (err || err !== undefined) {
      var errorArray = Object.keys(err);
      document.getElementById(errorArray[0])?.scrollIntoView({
        behavior: "smooth",
      });
      setErrors(err);
    } else {
      setErrors({});
    }

    if (Object.keys(err)?.length !== 0) {
      setErrors(err);
      return;
    } else {
      setErrors({});
    }

    var optionalAttributes = [];

    if (customAttributes?.childCover === "Yes") {
      optionalAttributes.push(
        ...childAttributes.map((x, i) => ({
          attributes: Object.keys(x).map((y) => ({
            name: y,
            value: x[y],
          })),
          externalReference: uuidv4(),
          valuations: [],
          productRiskReference: reducerState.riskAttributes.find(
            (x) => x.code === "CHI"
          )?.instanceId,
          price: 0.0,
          discount: 0.0,
          tax: 0.0,
          total: 0.0,
        }))
      );
    }

    if (customAttributes?.spouseCover === "Yes") {
      optionalAttributes.push({
        externalReference: uuidv4(),
        attributes: [
          ...reducerState.riskAttributes
            .find((x) => x.code === "SPO")
            ?.attributes.map((i) => ({
              ...i,
              value: i.value,
            })),
        ],
        valuations: [],
        productRiskReference: reducerState.riskAttributes.find(
          (x) => x.code === "SPO"
        )?.instanceId,
        price: 0.0,
        discount: 0.0,
        tax: 0.0,
        total: 0.0,
      });
    }

    if (getAttributes("Extended Family Cover")?.value === "Yes") {
      optionalAttributes.push(
        ...extendedFamilyAttributes.map((x, i) => ({
          attributes: Object.keys(x).map((y) => ({ name: y, value: x[y] })),
          externalReference: uuidv4(),
          valuations: [],
          productRiskReference: reducerState.riskAttributes.find(
            (x) => x.code === "EXF"
          )?.instanceId,
          price: 0.0,
          discount: 0.0,
          tax: 0.0,
          total: 0.0,
        }))
      );
    }
    const beneCountPercent = beneficiaries.reduce(
      (a, b) => a + b.percentage,
      0
    );

    if (beneCountPercent !== 100) {
      return setErrors({
        ...err,
        ["beneficiariesSplit"]: [
          `Benefit split for all beneficiaries must be total to 100%`,
        ],
      });
    }


    try {
      const data = {
        policies: [
          {
            startDate: null,
            endDate: null,
            PolicyStatus: "Quoted",
            currencyCode: "GHC",
            members: [
              {
                FirstName: inputs?.firstName,
                LastName: inputs?.lastName,
                BirthDate: inputs?.dob,
                MobilePhone: inputs?.mobilePhone,
                MobilePhoneCode: inputs?.mobilePhoneCode,
                NationalityId: inputs?.nationalityId,
                Nationality: inputs?.nationality,
                incomeTax: inputs?.incomeTax,
                MarriageStatus: inputs?.maritalStatus?.value,
                Gender: inputs?.mainGender?.value,
                LandlinePhone: inputs?.telePhone,
                Address: inputs?.physicalAddress,
                Address1: inputs?.address2,
                City: inputs?.city,
                Province: inputs?.region,
                PostalAddress: inputs?.postalAddress,
                PostalCity: inputs?.postalCity,
                PostalProvince: inputs?.postalProvince,
                WorkGPSCoordinate: inputs?.occupation,

                EMail: inputs?.eMail,
                memberType: 1,
                Relationship: 0,
              },
            ],

            beneficiaries: beneficiaries.reduce((acc, x) => {
              acc = [
                ...acc,
                {
                  beneficiaryType: 0,
                  firstName: x.firstName,
                  lastName: x.lastName,
                  country: x.country,
                  birthDate: x.dob,
                  nationalityId: x.nationalityId,
                  eMail: x.email,
                  telephoneNumber: x.telephoneNumber,
                  postalAddress: x.postalAddress,
                  gender: x.gender,
                  mobilePhone: x.number,
                  mobilePhoneCode: x?.["Phone Number Code"],
                  split: x.percentage / 100,
                },
              ];
              if (x.trusteeName) {
                acc = [
                  ...acc,
                  {
                    beneficiaryType: 2,
                    firstName: x.trusteeName,
                    lastName: x.trusteeLastName,
                    birthDate: x.trusteeDob,
                    mobilePhone: x.trusteeNumber,
                  },
                ];
              }
              return acc;
            }, []),
            attributes: [],
            risks: [
              {
                externalReference: uuidv4(),
                attributes: [
                  ...riskAttributes
                    ?.find((x) => x.code === "QUQ")
                    .attributes.map((y) => ({
                      dataType: y.dataType,
                      name: y.name,
                      instanceId: y.instanceId,
                      value:
                        y.name === "Package"
                          ? selectedOption?.name.includes("Anniversary")
                            ? "Family Care with 1st Anniversary Benefit"
                            : "Family Care"
                          : y.name === "Cover Amount"
                          ? coverAmount
                          : y.name === "Cover Option"
                          ? selectedOption?.name.includes("Anniversary")
                            ? "Family Care with 1st Anniversary Benefit"
                            : "Family Care"
                          : y.name === "Family Members"
                          ? customAttributes?.numberOfExtendedFamily ?? null
                          : y.value === "Yes"
                          ? "YES"
                          : "NO",
                    })),
                ],
                valuations:
                  reducerState?.executeCalculator2?.productOptions?.[0]
                    ?.valuations.values,
                productRiskReference: reducerState.riskAttributes.find(
                  (x) => x.code === "QUQ"
                )?.instanceId,
                price:
                  reducerState?.executeCalculator2?.productOptions?.[0]
                    ?.risks?.[0]?.rating?.total,
                discount: 0,
                tax: 0,
                total:
                  reducerState?.executeCalculator2?.productOptions?.[0]
                    ?.risks?.[0]?.rating?.total,
              },
              {
                externalReference: uuidv4(),
                attributes: [
                  ...reducerState.riskAttributes
                    .find((x) => x.code === "ADQ")
                    ?.attributes.map((i) => ({
                      ...i,
                      value: additionalInfo[i.name],
                    })),
                ],
                valuations: [],
                productRiskReference: reducerState.riskAttributes.find(
                  (x) => x.code === "ADQ"
                )?.instanceId,
                price: 0.0,
                discount: 0.0,
                tax: 0.0,
                total: 0.0,
              },
              ...optionalAttributes,
              {
                externalReference: uuidv4(),
                attributes: [
                  ...reducerState.riskAttributes
                    .find((x) => x.code === "MAM")
                    ?.attributes.map((i) => ({
                      ...i,
                      value:
                        i.name === "Occupation"
                          ? inputs?.occupation
                          : i.name === "Document Type"
                          ? inputs?.["Document Type"] ?? "Ghana Card"
                          : i.value,
                    })),
                ],
                valuations: [],
                productRiskReference: reducerState.riskAttributes.find(
                  (x) => x.code === "MAM"
                )?.instanceId,
                price: 0.0,
                discount: 0.0,
                tax: 0.0,
                total: 0.0,
              },
            ],
            bankAccounts: null,
            productOptionReference:
              reducerState?.executeCalculator2?.productOptions?.[0]
                ?.productOptionReference,
            status: 15,
          },
        ],
        networkId:
          process.env.REACT_APP_ENV_TYPE === "DEV"
            ? "d17679d9-fa59-dcf5-9e8a-71724b68d042"
            : process.env.REACT_APP_ENV_TYPE === "PROD"
            ? "8159bd2e-46aa-dfcc-ac83-a77e4be07a84"
            : agentOnBoarding?.network?.instanceId, // NetworkId from agentOnboarding
        companyId: referralData?.partnerCode,
        agentId: referralData?.agentCode,
      };
      setIsLoading(true);
      const res = await dispatch(CreatePolicy(data));
      setIsLoading(true); 
       //trackUserEvents
      trackUserEvents("gh_complete_your_details_next_cta", {
        FirstName: inputs?.firstName,
        LastName: inputs?.lastName,
        BirthDate: inputs?.dob,
        MobilePhone: inputs?.mobilePhone,
        MobilePhoneCode: inputs?.mobilePhoneCode,
        NationalityId: inputs?.nationalityId,
        Nationality: inputs?.nationality,
        incomeTax: inputs?.incomeTax,
        MarriageStatus: inputs?.maritalStatus?.value,
        Gender: inputs?.mainGender?.value,
        LandlinePhone: inputs?.telePhone,
        Address: inputs?.physicalAddress,
        Address1: inputs?.address2,
        City: inputs?.city,
        Province: inputs?.region,
        PostalAddress: inputs?.postalAddress,
        PostalCity: inputs?.postalCity,
        PostalProvince: inputs?.postalProvince,
        WorkGPSCoordinate: inputs?.occupation,

        EMail: inputs?.eMail,
        memberType: 1,
        Relationship: 0,
      });
      handleNext();
    } catch (err) {
      console.log(err);
      setIsLoading(false); 
    }
  };

   const handleBackTrack = () => {
     //trackUserEvents
     trackUserEvents("gh_complete_your_details_back_cta", {});
     handleBack();
   };

  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Family Care Assurance</h3>
            <h2 className="primary-color f-800">Complete your details</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Family Care Assurance</p>
          <h2 className="primary-color f-800">Complete your details</h2>
        </div>
      )}

      <div className="d-flex justify-content-center align-items-center flex-column mt-5">
        <Info>
          <p className="primary-color">
            Please complete the details below for the policy holder and
            beneficiaries, click “Next” by scrolling to the bottom of the page
            to proceed to payment
          </p>
        </Info>
        <div
          style={{ width: width > 770 ? "460px" : "90%", marginTop: "20px" }}
        >
          <h5 className="primary-color f-700 text-left">
            Please enter your personal details here:
          </h5>
        </div>
        <div
          style={{ width: width > 770 ? "460px" : "90%", marginTop: "20px" }}
        >
          <Accordion
            defaultExpanded={expand.policy}
            onChange={() => setExpand({ ...expand, policy: !expand.policy })}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700" id="accord-number" >1</span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Add policy holder details here
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0 mt-4">
              <div
                style={{
                  border: "1px solid #00000030",
                  padding: 20,
                  borderRadius: 5,
                }}
              >
                <TextInput
                  id={"firstName"}
                  label={"First name"}
                  name={"firstName"}
                  value={inputs?.firstName}
                  errors={errors?.["firstName"]}
                  onChange={(e) => {
                    handleChangePolicy(e);
                  }}
                  required={true}
                />
                <TextInput
                  id={"lastName"}
                  label={"Last name or surname"}
                  name={"lastName"}
                  value={inputs?.lastName}
                  errors={errors?.["lastName"]}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />

                {/* //to be updated to state and passed in createPolicySale */}
                {/* //new field for Gender added */}

                <div className="form-group mt-3">
                  <label className="primary-color f-700">Gender*</label>

                  <ReactSelect
                    id={`mainGender`}
                    placeholder="Select Gender"
                    value={inputs?.mainGender}
                    options={[
                      { value: "Male", label: "Male" },
                      { value: "Female", label: "Female" },
                    ]}
                    onChange={(e) => {
                      handleChangePolicy({
                        target: { name: "mainGender", value: e },
                      });
                    }}
                  />
                  {errors?.["mainGender"] && (
                    <span className="text-danger errors">
                      {errors?.["mainGender"] && errors?.["mainGender"][0]}
                    </span>
                  )}
                </div>
                {renderComponents(
                  riskAttributes.find((x) => x.code === "MAM")?.attributes[1],
                  "mainMem"
                )}
                {errors?.["Document Type"] && (
                  <span className="text-danger errors">
                    {errors?.["Document Type"] && errors?.["Document Type"][0]}
                  </span>
                )}

                <NumberInput
                  id={"nationalityId"}
                  label={"Identification number"}
                  name={"nationalityId"}
                  value={inputs?.nationalityId}
                  errors={errors?.["nationalityId"]}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />
                <DateInput
                  id={"dob"}
                  label={"Date of birth"}
                  name={"dob"}
                  minDate={calculateMinDate("parent")}
                  errors={errors?.["dob"]}
                  maxDate={calculateMaxDate("parent")}
                  value={inputs?.dob}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />
                <TextInput
                  id={"nationality"}
                  label={"Nationality"}
                  name={"nationality"}
                  errors={errors?.["nationality"]}
                  value={inputs?.nationality}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />

                <div className="form-group mt-3">
                  <label className="primary-color f-700">Marital status*</label>
                  <ReactSelect
                    id={`maritalStatus`}
                    placeholder="Select Status"
                    value={inputs?.maritalStatus}
                    options={[
                      { value: "Married", label: "Married" },
                      { value: "Single", label: "Single" },
                    ]}
                    onChange={(e) => {
                      handleChangePolicy({
                        target: { name: "maritalStatus", value: e },
                      });
                    }}
                  />
                  {errors?.["maritalStatus"] && (
                    <span className="text-danger errors">
                      {errors?.["maritalStatus"] &&
                        errors?.["maritalStatus"][0]}
                    </span>
                  )}
                </div>
                <div className="row">
                  {width > 1024 ? (
                    <>
                      <div className="col-4">
                        <CountryInput
                          id={"mobilePhoneCode"}
                          label={"Country code"}
                          name={"mobilePhoneCode"}
                          errors={errors?.["mobilePhoneCode"]}
                          value={inputs?.mobilePhoneCode}
                          placeholder={"+255"}
                          onChange={(e) =>
                            handleChangePolicy(e, "mobilePhoneCode")
                          }
                          required={true}
                          country={true}
                        />
                      </div>
                      <div className="col-8">
                        <NumberInput
                          id={"mobilePhone"}
                          type="number"
                          label={"Mobile number"}
                          name={"mobilePhone"}
                          errors={errors?.["mobilePhone"]}
                          value={
                            Number(inputs?.mobilePhone) === 0 ||
                            inputs?.mobilePhone === undefined ||
                            inputs?.mobilePhone === null
                              ? ""
                              : Number(inputs?.mobilePhone).toString()
                          }
                          onChange={(e) => {
                            if (e?.target?.value?.length <= 12) {
                              handleChangePolicy(e);
                            }
                          }}
                          required={true}
                          mobile={true}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-11">
                        <CountryInput
                          id={"mobilePhoneCode"}
                          label={"Country code"}
                          name={"mobilePhoneCode"}
                          errors={errors?.["mobilePhoneCode"]}
                          value={inputs?.mobilePhoneCode}
                          placeholder={"+255"}
                          onChange={(e) =>
                            handleChangePolicy(e, "mobilePhoneCode")
                          }
                          required={true}
                          country={true}
                        />
                      </div>
                      <div className="col-12">
                        <NumberInput
                          id={"mobilePhone"}
                          type="number"
                          label={"Mobile number"}
                          name={"mobilePhone"}
                          errors={errors?.["mobilePhone"]}
                          value={
                            Number(inputs?.mobilePhone) === 0 ||
                            inputs?.mobilePhone === undefined ||
                            inputs?.mobilePhone === null
                              ? ""
                              : Number(inputs?.mobilePhone).toString()
                          }
                          onChange={(e) => handleChangePolicy(e)}
                          required={true}
                          mobile={true}
                        />
                      </div>
                    </>
                  )}
                </div>
                <TextInput
                  id={"occupation"}
                  label={"Occupation"}
                  name={"occupation"}
                  errors={errors?.["occupation"]}
                  value={inputs?.occupation}
                  onChange={(e) => handleChangePolicy(e)}
                  required={false}
                />

                <TextInput
                  id={"eMail"}
                  label={"Email address*"}
                  name={"eMail"}
                  value={inputs?.eMail}
                  errors={errors?.["eMail"]}
                  onChange={(e) => handleChangePolicy(e)}
                  required={false}
                />
                <div className="form-group mt-3">
                  <label className="primary-color f-700">
                    Physical address
                  </label>
                </div>
                <TextInput
                  id={"physicalAddress"}
                  label={"Address 1"}
                  errors={errors?.["physicalAddress"]}
                  name={"physicalAddress"}
                  value={inputs?.physicalAddress}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />
                <TextInput
                  id={"address2"}
                  label={"Address 2"}
                  errors={errors?.["address2"]}
                  name={"address2"}
                  value={inputs?.address2}
                  onChange={(e) => handleChangePolicy(e)}
                  required={false}
                />
                <TextInput
                  id={"city"}
                  label={"City"}
                  errors={errors?.["city"]}
                  name={"city"}
                  value={inputs?.city}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />

                <TextInput
                  id={"region"}
                  label={"Region"}
                  errors={errors?.["region"]}
                  name={"region"}
                  value={inputs?.region}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />

                <div className="form-group mt-3">
                  <label className="primary-color f-700">Postal address</label>
                </div>
                <TextInput
                  id={"postalAddress"}
                  label={"Address 1"}
                  errors={errors?.["postalAddress"]}
                  name={"postalAddress"}
                  placeholder="If not the same as physical address"
                  value={inputs?.postalAddress}
                  onChange={(e) => handleChangePolicy(e)}
                  required={false}
                />
                <TextInput
                  id={"postalCity"}
                  label={"City"}
                  errors={errors?.["postalCity"]}
                  name={"postalCity"}
                  value={inputs?.postalCity}
                  onChange={(e) => handleChangePolicy(e)}
                  required={false}
                />
                <TextInput
                  id={"postalProvince"}
                  label={"Region"}
                  errors={errors?.["postalProvince"]}
                  name={"postalProvince"}
                  value={inputs?.postalProvince}
                  onChange={(e) => handleChangePolicy(e)}
                  required={false}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          {errors && errors["inputState"] && (
            <span className="errors">{errors["inputState"][0]}</span>
          )}
          {customAttributes?.spouseCover !== "No" && (<>
            <div style={{ height: "20px" }} />
          <Accordion
            disabled={customAttributes?.spouseCover === "No"}
            defaultExpanded={
              customAttributes?.spouseCover === "No" ? false : expand.spouse
            }
            onChange={() => setExpand({ ...expand, spouse: !expand.spouse })}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700" id="accord-number">2</span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Add spouse details here
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0 mt-4">
              <div
                style={{
                  border: "1px solid #00000030",
                  padding: 20,
                  borderRadius: 5,
                }}
              >
                {riskAttributes
                  .find((x) => x.code === "SPO")
                  ?.attributes.map((y) => renderComponents(y))}
              </div>
            </AccordionDetails>
          </Accordion>
          {errors && errors["spouseState"] && (
            <span className="errors">{errors["spouseState"][0]}</span>
          )}
          </>
        )}


          {customAttributes?.childCover !== "No" && (<>
            <div style={{ height: "20px" }} />
          <Accordion
            disabled={customAttributes?.childCover === "No"}
            defaultExpanded={
              customAttributes?.childCover === "No" ? false : expand.child
            }
            onChange={() => setExpand({ ...expand, child: !expand.child })}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700" id="accord-number">3</span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Add child details here
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0 mt-4">
              {getRows(customAttributes?.numberOfChildrens).map((x, i) => (
                <div
                  style={{
                    border: "1px solid #00000030",
                    marginTop: 20,
                    padding: 20,
                    borderRadius: 5,
                  }}
                >
                  <p className="primary-color f-700">Child #{i + 1}</p>
                  {riskAttributes
                    .find((x) => x.code === "CHI")
                    ?.attributes.map((y) => renderComponents(y, "child", i))}
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
          {errors && errors["childState"] && (
            <span className="errors">{errors["childState"][0]}</span>
          )}
          </>
        )}


          {getAttributes("Extended Family Cover")?.value !== "No" && (
            <>
          <div style={{ height: "20px" }} />
          <Accordion
            disabled={
              getAttributes("Extended Family Cover")?.value === "No" ||
              customAttributes?.numberOfExtendedFamily === 0
            }
            defaultExpanded={
              getAttributes("Extended Family Cover")?.value === "No"
                ? false
                : expand.parent
            }
            onChange={() => setExpand({ ...expand, parent: !expand.parent })}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700" id="accord-number">4</span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Add extended family details here
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0 mt-4">
              {getRows(customAttributes?.numberOfExtendedFamily).map((x, i) => (
                <div
                  style={{
                    border: "1px solid #00000030",
                    marginTop: 20,
                    padding: 20,
                    borderRadius: 5,
                  }}
                >
                  <p className="primary-color f-700">
                    Extended Family #{i + 1}
                  </p>
                  {riskAttributes
                    .find((x) => x.code === "EXF")
                    ?.attributes.map((y) => renderComponents(y, "extended", i))}
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
          {errors && errors["extendedFamilyState"] && (
            <span className="errors">{errors["extendedFamilyState"][0]}</span>
          )}
          </>
        )}
          {/* <-------------- Additional Info ----------------> */}

          <div style={{ height: "20px" }} />
          <Accordion
            defaultExpanded={expand.beneficiaries}
            onChange={() =>
              setExpand({ ...expand, beneficiaries: !expand.beneficiaries })
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="pane41a-content"
              id="panel4a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700" id="accord-number">5</span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Add beneficiaries details here
                </p>
              </div>
            </AccordionSummary>

            <AccordionDetails className="p-0 mt-4">
              {getRows(beneCount)?.map((x, i) => (
                <div
                  style={{
                    border: "1px solid #00000030",
                    marginTop: 20,
                    padding: 20,
                    borderRadius: 5,
                  }}
                >
                  <p className="primary-color f-700">Beneficiary #{i + 1}</p>

                  <div style={{ marginTop: 20 }} />

                  <TextInput
                    id={`First Name ${i + 1}`}
                    label={"First name"}
                    name={"firstName"}
                    value={beneficiaries[i]?.["firstName"]}
                    errors={errors[`First name ${i + 1}`]}
                    onChange={(e) => handleChangeBene(e, i)}
                    required={true}
                  />

                  <TextInput
                    id={`Last Name ${i + 1}`}
                    label={"Last name"}
                    name={"lastName"}
                    value={beneficiaries[i]?.["lastName"]}
                    errors={errors[`Last name ${i + 1}`]}
                    onChange={(e) => handleChangeBene(e, i)}
                    required={true}
                  />
                  <div className="row"> 
                  {width > 1024 ? <>
                    <div className="col-4">
                    <CountryInput
                        id={`Phone Number Code ${i + 1}`}
                        label={"Country code"}
                        name={"Phone Number Code"}
                        errors={errors?.[`Phone Number Code ${i + 1}`]}
                        value={beneficiaries[i]?.["Phone Number Code"]}
                        placeholder={"+233"}
                        onChange={(e) =>
                          handleChangeBene(
                            { target: { name: "Phone Number Code", value: e } },
                            i
                          )
                        }
                        required={true}
                        country={true}
                      /> 
                  </div>
                  <div className="col-8">
                  <NumberInput
                    id={`Phone Number ${i + 1}`}
                    label={"Mobile number"}
                    name={"number"}
                    type="number"
                    value={beneficiaries[i]?.["number"]}
                    errors={errors[`Phone Number ${i + 1}`]}
                    onChange={(e) => {
                      if(e.target?.value?.length <=12){
                        handleChangeBene(e, i)
                      }
                    }}
                    required={true}
                  />
                </div>
                  </> : 
                  <>
                    <div className="col-11">
                    <CountryInput
                        id={`Phone Number Code ${i + 1}`}
                        label={"Country code"}
                        name={"Phone Number Code"}
                        errors={errors?.[`Phone Number Code ${i + 1}`]}
                        value={beneficiaries[i]?.["Phone Number Code"]}
                        placeholder={"+233"}
                        onChange={(e) =>
                          handleChangeBene(
                            { target: { name: "Phone Number Code", value: e } },
                            i
                          )
                        }
                        required={false}
                        country={true}
                      /> 
                  </div>
                  <div className="col-12">
                  <NumberInput
                    id={`Phone Number ${i + 1}`}
                    label={"Phone number"}
                    name={"number"}
                    type="number"
                    value={beneficiaries[i]?.["number"]}
                    errors={errors[`Phone Number ${i + 1}`]}
                    onChange={(e) => {
                      if(e.target?.value?.length <=12){
                        handleChangeBene(e, i)
                      }
                    }}
                    required={true}
                  />
                </div>
                  </>}
               </div>
                  <TextInput
                    id={`Email ${i + 1}`}
                    label={"Email address"}
                    name={"email"}
                    value={beneficiaries[i]?.["email"]}
                    errors={errors[`Email ${i + 1}`]}
                    onChange={(e) => handleChangeBene(e, i)}
                    required={true}
                  />

                  <DateInput
                    id={`dob ${i + 1}`}
                    label={"Date of birth"}
                    name={"dob"}
                    minDate={calculateBeneMinDate()}
                    errors={errors[`Date of birth ${i + 1}`]}
                    maxDate={calculateBeneMaxDate()}
                    value={beneficiaries[i]?.["dob"]}
                    onChange={(e) => handleChangeBene(e, i)}
                    required={true}
                  />

                  <div className="form-group mt-3">
                    <label className="primary-color f-700">
                      Percentage of benefit*
                    </label>
                    <ReactSelect
                      id={`Percentage ${i + 1}`}
                      placeholder="0"
                      defaultValue={[
                        ...percentageArr.map((x) => ({
                          label: `${x.name}`,
                          value: x.value,
                        })),
                        ...range(0, 100).map((x, i) => ({
                          label: `${x}%`,
                          value: x,
                        })),
                      ].find(
                        (z) => z.value === beneficiaries[i]?.["percentage"]
                      )}
                      value={[
                        ...percentageArr.map((x) => ({
                          label: `${x.name}`,
                          value: x.value,
                        })),
                        ...range(0, 100).map((x, i) => ({
                          label: `${x}%`,
                          value: x,
                        })),
                      ].find(
                        (z) => z.value === beneficiaries[i]?.["percentage"]
                      )}
                      options={
                        isTyped
                          ? range(0, 100).map((x, i) => ({
                              label: `${x}%`,
                              value: x,
                            }))
                          : percentageArr.map((x) => ({
                              label: `${x.name}`,
                              value: x.value,
                            }))
                      }
                      onInputChange={(e) => {
                        if (e > 0) {
                          setIsTyped(true);
                        } else {
                          setIsTyped(false);
                        }
                      }}
                      onChange={(e) => {
                        handleChangeBene(
                          { target: { name: "percentage", value: e.value } },
                          i
                        );
                      }}
                      isSearchable={true}
                    />
                  </div>
                  {(
                    errors[`Percentage ${i + 1}`] ||
                    errors[`beneficiariesSplit`]
                  )?.length && (
                    <span
                      className="text-danger errors"
                      style={{ marginLeft: 0 }}
                    >
                      {errors[`Percentage ${i + 1}`] ||
                        (errors[`beneficiariesSplit`] &&
                          errors[`Percentage ${i + 1}`]) ||
                        errors[`beneficiariesSplit`][0]}
                    </span>
                  )}
                  {/*--- Trustee--starts---- */}
                  {beneficiaries[i]?.["dob"] &&
                    calculateage(beneficiaries[i]?.["dob"]) && (
                      <div
                        style={{
                          border: "1px solid #00000030",
                          marginTop: 20,
                          padding: 20,
                          borderRadius: 5,
                        }}
                      >
                        <p className="primary-color f-700">Trustee</p>
                        <TextInput
                          id={`Trustee First Name ${i + 1}`}
                          label={"First name"}
                          name={"trusteeName"}
                          value={beneficiaries[i]?.["trusteeName"]}
                          errors={errors[`Trustee First Name ${i + 1}`]}
                          onChange={(e) => handleChangeBene(e, i)}
                          required={true}
                        />
                        <TextInput
                          id={`Trustee Last Name ${i + 1}`}
                          label={"Last name"}
                          name={"trusteeLastName"}
                          value={beneficiaries[i]?.["trusteeLastName"]}
                          errors={errors[`Trustee Last Name ${i + 1}`]}
                          onChange={(e) => handleChangeBene(e, i)}
                          required={true}
                        />
                        <div className="row">
                          {width > 1024 ? (
                            <>
                              <div className="col-4">
                                <CountryInput
                                  id={"trusteeMobilePhoneCode"}
                                  label={"Country code"}
                                  name={"trusteeMobilePhoneCode"}
                                  errors={
                                    errors?.[`trusteeMobilePhoneCode ${i + 1}`]
                                  }
                                  value={
                                    beneficiaries[i]?.["trusteeMobilePhoneCode"]
                                  }
                                  placeholder={"+233"}
                                  onChange={(e) => handleChangeBene({ target: {name: 'trusteeMobilePhoneCode', value: e} }, i)}
                                  required={true}
                                  country={true}
                               
                                />
                              </div>
                              <div className="col-8">
                                <NumberInput
                                  id={`Trustee Phone Number ${i + 1}`}
                                  label={"Mobile number"}
                                  name={"trusteeNumber"}
                                  type="number"
                                  value={beneficiaries[i]?.["trusteeNumber"]}
                                  errors={
                                    errors[`Trustee Phone Number ${i + 1}`]
                                  }
                                  style={{width: '100%'}}
                                  
                                  onChange={(e) => {
                                    if(e.target?.value?.length <=12){
                                      handleChangeBene(e, i)
                                    }
                                  }}
                                  required={true}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-12">
                                <CountryInput
                                  id={"trusteeMobilePhoneCode"}
                                  label={"Country code"}
                                  name={"trusteeMobilePhoneCode"}
                                  errors={
                                    errors?.[`trusteeMobilePhoneCode ${i + 1}`]
                                  }
                                  value={
                                    beneficiaries[i]?.["trusteeMobilePhoneCode"]
                                  }
                                  placeholder={"+233"}
                                  onChange={(e) => handleChangeBene({ target: {name: 'trusteeMobilePhoneCode', value: e} }, i)}
                                  required={true}
                                  country={true}
                                />
                              </div>
                              <div className="col-12">
                                <NumberInput
                                  id={`Trustee Phone Number ${i + 1}`}
                                  label={"Mobile number"}
                                  name={"trusteeNumber"}
                                  type="number"
                                  value={beneficiaries[i]?.["trusteeNumber"]}
                                  errors={
                                    errors[`Trustee Phone Number ${i + 1}`]
                                  }
                                  onChange={(e) => {
                                    if(e.target?.value?.length <=12){
                                      handleChangeBene(e, i)
                                    }
                                  }}
                                  required={true}
                                  mobile={true}
                                />
                              </div>
                            </>
                          )}
                        </div>

                        <DateInput
                          id={`Trustee dob ${i + 1}`}
                          label={"Date of birth"}
                          name={"trusteeDob"}
                          minDate={calculateBeneMinDate()}
                          errors={errors[`Trustee dob ${i + 1}`]}
                          maxDate={calculateTrusteeMaxDate()}
                          value={beneficiaries[i]?.["trusteeDob"]}
                          onChange={(e) => handleChangeBene(e, i)}
                          required={true}
                        />
                      </div>
                    )}
                </div>
              ))}
              <div className="d-flex justify-content-end w-100 mt-4">
                <button
                  className="delete-button"
                  onClick={() => setBeneCount(beneCount - 1)}
                >
                  Delete
                </button>
                <button
                  className="add-button"
                  onClick={() => {
                    var arr = [...beneficiaries];
                    arr.push({
                      trusteeMobilePhoneCode: "+233",
                    });
                    setBeneficiaries(arr);
                    dispatch(
                      updateCustomAttributes({
                        type: "BENEFICIARIES",
                        payload: arr,
                      })
                    );
                    setBeneCount(beneCount + 1);
                  }}
                >
                  Add Beneficiaries
                </button>
              </div>
            </AccordionDetails>
          </Accordion>
          {errors && errors["beneficiaries"] && (
            <span className="errors">{errors["beneficiaries"][0]}</span>
          )}

          <div style={{ height: "20px" }} />
          <Accordion
            defaultExpanded={expand?.add}
            onChange={() => setExpand({ ...expand, add: !expand.add })}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700" id="accord-number">6</span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Additional Information
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0 mt-4">
              <div
                style={{
                  border: "1px solid #00000030",
                  marginTop: 20,
                  padding: 20,
                  borderRadius: 5,
                }}
              >
                <p className="primary-color f-700"></p>
                {riskAttributes
                  .find((x) => x.code === "ADQ")
                  ?.attributes.map((y) =>
                    renderComponents(y, "additional", null)
                  )}
              </div>
            </AccordionDetails>
          </Accordion>
          {errors && errors["additionalState"] && (
            <span className="errors">{errors["additionalState"][0]}</span>
          )}
        </div>
      </div>

      <NavigationButtons
        back={handleBackTrack}
        payment={true}
        loading={isLoading}
        isBackDisabled={isLoading}
        next={() => {
          handleSubmit();
        }}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}
    </div>
  );
};

const schema = {
  firstName: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[-'\sa-zA-Z]+$/,
      message: "^ Only alphabetic characters, hyphen and space are allowed",
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[-'\sa-zA-Z]+$/,
      message: "^ Only alphabetic characters, hyphen and space are allowed",
    },
  },
  nationalityId: {
    presence: {
      allowEmpty: false,
      message: "^ ID can't be blank"
    },
    format: {
      pattern: /^[a-zA-Z0-9]+$/,
      message:"^ Only alphabetic characters, numbers are allowed",
    },
  },
  dob: {
    presence: {
      allowEmpty: false,
    },
  },
  nationality: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[-\sa-zA-Z]+$/,
      message:"^ Only alphabetic characters, hyphen and space are allowed",
    },
  },
  maritalStatus: {
    presence: {
      allowEmpty: false,
    },
  },
  ["Document Type"]: {
    presence: {
      allowEmpty: false,
    },
  },
  mobilePhone: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern:  /^(\+\d{1,3}[- ]?)?\d{8,12}$/,
      message: "^ Invalid mobile number",
    },
  },
  mobilePhoneCode: {
    presence: {
      allowEmpty: false,
    },
  },
  physicalAddress: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[-\sa-zA-Z0-9,]+$/,
      message:"^ Only alphabetic characters, numbers, comma, hyphen and space are allowed",
    },
  },
  city: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[-\sa-zA-Z]+$/,
      message:"^ Only alphabetic characters, hyphen and space are allowed",
    },
  },
  region: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[-\sa-zA-Z]+$/,
      message:"^ Only alphabetic characters, hyphen and space are allowed",
    },
  },
  mainGender: {
    presence: {
      allowEmpty: false,
      message: "^ Gender can't be blank"
    },
  },
  eMail: {
    presence: {
      allowEmpty: false,
      message: "^ Email can't be blank"
    },
    email: true,
  },
};

const percentageArr = [
  {
    name: "10%",
    value: 10,
  },
  {
    name: "20%",
    value: 20,
  },
  {
    name: "30%",
    value: 30,
  },
  {
    name: "40%",
    value: 40,
  },
  {
    name: "50%",
    value: 50,
  },
  {
    name: "60%",
    value: 60,
  },
  {
    name: "70%",
    value: 70,
  },
  {
    name: "80%",
    value: 80,
  },
  {
    name: "90%",
    value: 90,
  },
  {
    name: "100%",
    value: 100,
  },
];

export default Step4;

